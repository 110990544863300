import Cookies from "js-cookie";
const pres_cookies = process.env.REACT_APP_COOKIES_PREFIX;
export const setUserInfos = (user, token, customer, language = {}) => {
  Cookies.set("loggedIn" + pres_cookies, 1);
  Cookies.set("userId"+pres_cookies, user.id);
  Cookies.set("dataUserInfo"+pres_cookies, JSON.stringify(user));
  Cookies.set("datacustomerInfo"+pres_cookies, JSON.stringify(customer));
  Cookies.set("token"+pres_cookies, token);
  Cookies.set("language"+pres_cookies, JSON.stringify(language));
};

export const setUserLanguage = (lang) => {
  Cookies.set("lang"+pres_cookies, JSON.stringify(lang));
};

export const removeUserInfos = () => {
  Cookies.remove("loggedIn" + pres_cookies);
  Cookies.remove("isAuthenticatedAD"+pres_cookies);
  Cookies.remove("userId"+pres_cookies);
  Cookies.remove("dataUserInfo"+pres_cookies);
  Cookies.remove("datacustomerInfo"+pres_cookies);

  Cookies.remove("token"+pres_cookies);
  Cookies.remove("language"+pres_cookies);
};

export const getUserInfos = () => {
  if (Cookies.get("dataUserInfo"+pres_cookies)) {
    return JSON.parse(Cookies.get("dataUserInfo"+pres_cookies));
  }
};
export const getCustomerInfos = () => {
  if (Cookies.get("datacustomerInfo"+pres_cookies)) {
    return JSON.parse(Cookies.get("datacustomerInfo"+pres_cookies));
  }
};

export const getToken = () => {
  if (Cookies.get("token"+pres_cookies)) {
    return Cookies.get("token"+pres_cookies);
  }
};

export const getUserId = () => {
  if (Cookies.get("userId"+pres_cookies)) {
    return Cookies.get("userId"+pres_cookies);
  }
};

// Shopping cart
export const setCartInfos = (item) => {
  Cookies.set("cartInfos"+pres_cookies, JSON.stringify(item));
};
export const getCartInfos = () => {
  if (Cookies.get("cartInfos"+pres_cookies)) {
    return JSON.parse(Cookies.get("cartInfos"+pres_cookies));
  }
};
export const removeCartInfos = () => {
  Cookies.remove("cartInfos"+pres_cookies);
};

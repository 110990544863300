import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Rating from "./ProductsDetails/Components/Rating";
import blankimage from "../../media/blank-image.svg";
const ProductCard = ({ el }) => {
  const navigate = useNavigate();

  return (
    <>
      {el && ( <div className="col-lg-4 col-md-4  mb-4">

        <div className=" bg-white rounded card shadow-sm w-100 h-100">
          <img
            src={el.default_image?el.default_image:blankimage}
            alt="pic"
            className="rounded-top  mw-100 h-75 border-bottom p-2"
          />
          <div className="p-5">
            <h3 className=" fw-bloder fs-2 ms-5 ">{el.name}</h3>
            <div className="d-flex  justify-content-between">
              <h6 className="text-muted fs-5 ms-5 fw-bloder">${el.price}</h6>
              <Rating />
            </div>

            <div
              className="post-meta d-flex justify-content-center mt-3"
              onClick={() => navigate(`/product/${el.id}`)}
            >
              <a
                className="btn btn-pills  btn-light-warning btn-sm p-0 pe-2 ps-6"
                style={{
                  borderRadius: "20px",
                }}
              >
                Plus de détails
                <span className="svg-icon svg-icon-muted svg-icon-2hx">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 40 26"
                    fill="none"
                  >
                    <path
                      d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ProductCard;

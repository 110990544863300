import { combineReducers } from "redux";
import authReducer from "./authReducer";
import domainReducer from "./domainReducer";
import productsReducer from "./productsReducer";
import cartReducer from "./cartReducer";
import customerReducer from "./customerReducer";
import quotationsReducer from "./quotationsReducer";
import alertReducer from "./alertReducer";


const reducers = combineReducers({
    authReducer,
    domainReducer,
    productsReducer,
    cartReducer,
    customerReducer,
    quotationsReducer: quotationsReducer,
    alertReducer: alertReducer
})

const rootReducer = (state, { type, payload }) => {
    if (type === "LOGOUT") {
        state = undefined;
    }
    return reducers(state, { type, payload });
};

export default rootReducer;
import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'Avril 04',
        ventes: 4000,

    },
    {
        name: 'Avril 08',
        ventes: 3000,

    },
    {
        name: 'Avril 12',
        ventes: 2000,

    },
    {
        name: 'Avril 15',
        ventes: 2780,

    },
    {
        name: 'Avril 18',
        ventes: 1890,

    },
    {
        name: 'Avril 21',
        ventes: 2390,

    },
    {
        name: 'Avril 22',
        ventes: 3490,

    },
];

export default class Example extends PureComponent {
    static demoUrl = 'https://codesandbox.io/s/synchronized-area-chart-kpg1s';

    render() {
        return (
            <div style={{ width: '100%' }}>
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart
                        width={500}
                        height={200}
                        data={data}
                        syncId="anyId"
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="ventes" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                </ResponsiveContainer>


            </div>
        );
    }
}

import Cookies from "js-cookie";
const pres_cookies = process.env.REACT_APP_COOKIES_PREFIX;

export const setStoreData = (result) => {
  Cookies.set("storeData" +pres_cookies, JSON.stringify(result));
};
export const getStoreData = () => {
  if (Cookies.get("storeData"+pres_cookies)) {
    return JSON.parse(Cookies.get("storeData"+pres_cookies));
  } else {
    return {};
  }
};
export const setaccesskey = (result) => {
  Cookies.set("accesskey"+pres_cookies, JSON.stringify(result));
};
export const getaccesskey = () => {
  if (Cookies.get("accesskey"+pres_cookies)) {
    return JSON.parse(Cookies.get("accesskey"+pres_cookies));
  } else {
    return "";
  }
};
export const setProductsData = (result) => {
  Cookies.set("productsData"+pres_cookies, JSON.stringify(result));
};
export const getProductsData = () => {
  if (Cookies.get("productsData"+pres_cookies)) {
    return JSON.parse(Cookies.get("productsData"+pres_cookies));
  } else {
    return {};
  }
};
export const setMetaData = (result) => {
  Cookies.set("metaData"+pres_cookies, JSON.stringify(result));
};
export const getMetaData = () => {
  if (Cookies.get("metaData"+pres_cookies)) {
    return JSON.parse(Cookies.get("metaData"+pres_cookies));
  } else {
    return {};
  }
};
export const setStoreId = (result) => {
  Cookies.set("storeId"+pres_cookies, JSON.stringify(result));
};
export const getStoreId = () => {
  if (Cookies.get("storeId"+pres_cookies)) {
    return JSON.parse(Cookies.get("storeId"+pres_cookies));
  } else {
    return {};
  }
};
export const setCartData = (result) => {
  Cookies.set("cartData"+pres_cookies, JSON.stringify(result));
};
export const getCartData = () => {
  if (Cookies.get("cartData"+pres_cookies)) {
    return JSON.parse(Cookies.get("cartData"+pres_cookies));
  } else {
    return {};
  }
};

import { toast } from "react-toastify";

const options = {
  position: "top-center",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const renderToast = (text, type = "error") => {
    switch (type) {
        case "error":
          return toast.error(text, options);
        case "success":
          return toast.success(text, options);
        case "warning":
          return toast.warning(text, options);
        default:
          return "";
    }
}
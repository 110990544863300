import { Button, Modal, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import InputMask from "react-input-mask";
const PaymentWithStripe = ({ show, onHide, onSubmit, quotation_data }) => {
  const error_Message = "Required !";
  const [years, setYears] = useState([]);
  const [amount, setAmount] = useState(0);
  function Years() {
    const products_list = quotation_data.products;
    let amount = 0;
    let date = new Date().getFullYear();
    let tab = [];
    for (let index = 0; index < 10; index++) {
      tab.push(date.toString());
      date++;
    }
    if (!_.isEmpty(products_list)) {
      amount = products_list.reduce(
        (total, item) => total + item.price * item.number,
        0
      );
    }
    setAmount(amount);

    setYears(tab);
  }
  function copyText() {
    var copyTextareaBtn = document.getElementById(".js-textareacopybtn");

    copyTextareaBtn.addEventListener("click", function (event) {
      var copyTextarea = document.querySelector("js-copytextarea");
      copyTextarea.focus();
      copyTextarea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Copying text command was " + msg);
      } catch (err) {
        console.log("Oops, unable to copy");
      }
    });
  }
  useEffect(() => {
    Years();
  }, []);

  return (
    <Formik
      initialValues={{
        name: "",
        card_number: "",
        month: "",
        year: "",
        cvv: "",
        amount: amount,
        quotation_id:quotation_data?.id
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(`${error_Message}`),
        card_number: Yup.number()
          .typeError("Numéro doit étre composer de 16 chiffres ")
          .min(16, "Numéro doit étre composer de  16 chiffres ")
          .required(`${error_Message}`),
        month: Yup.string().required(`${error_Message}`),
        year: Yup.string().required(`${error_Message}`),
        cvv: Yup.number()
          .typeError("Numéro doit étre composer de 16 chiffres ")
          .min(3, "Numéro doit étre composer de  16 chiffres ")
          .required(`${error_Message}`),
      })}
      onSubmit={(fields) => {
        const values = {
          ...fields,
          card_number: fields["card_number"].replace(/[^0-9]/g, ""),
          cvv: parseInt(fields["cvv"].replace(/[^0-9]/g, "")),
        };
        onSubmit(values);
      }}
    >
      {({ touched, values, handleSubmit, handleChange, errors }) => (
        <Modal
          onHide={onHide}
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Payment with Stripe
            </Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="w-100">
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                    <span className="required">Name On Card</span>
                    <i
                      className="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      aria-label="Specify a card holder's name"
                      data-bs-original-title="Specify a card holder's name"
                      data-kt-initialized={1}
                    />
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className={
                      "form-control" +
                      (errors.name && touched.name ? " is-invalid" : "") +
                      (!errors.name && touched.name ? " is-valid" : "")
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />

                  <div className="fv-plugins-message-container invalid-feedback" />
                </div>
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="required fs-6 fw-semibold form-label mb-2">
                    Card Number
                  </label>
                  <div className="position-relative">
                    <Field
                      name="card_number"
                      type="text"
                      className={
                        "form-control" +
                        (errors.card_number && touched.card_number
                          ? " is-invalid"
                          : "") +
                        (!errors.card_number && touched.card_number
                          ? " is-valid"
                          : "")
                      }
                      autoComplete="none"
                      as={InputMask}
                      mask=" 999 999 999 999 9999"
                      formatChars={{
                        9: "[0-9]",
                      }}
                      aria-describedby="CardNumberHelp"
                    />
                    <ErrorMessage
                      name="card_number"
                      component="div"
                      className="invalid-feedback"
                    />
                    <div id="CardNumberHelp" className="form-text d-flex">
                      Example: <span>4242424242424242</span>
                      {/* <button type="button" className="js-textareacopybtn" >Copy Textarea</button> */}
                    </div>

                    <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                      <img
                        src="/metronic8/demo1/assets/media/svg/card-logos/visa.svg"
                        alt=""
                        className="h-25px"
                      />
                      <img
                        src="/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg"
                        alt=""
                        className="h-25px"
                      />
                      <img
                        src="/metronic8/demo1/assets/media/svg/card-logos/american-express.svg"
                        alt=""
                        className="h-25px"
                      />
                    </div>
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback" />
                </div>
                <div
                  className="row mb-10"
                  data-select2-id="select2-data-129-obbo"
                >
                  <div
                    className="col-md-8 fv-row"
                    data-select2-id="select2-data-128-s2kt"
                  >
                    <div
                      className="row fv-row fv-plugins-icon-container"
                      data-select2-id="select2-data-127-mbpe"
                    >
                      <div className="col-6">
                        <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2  ">
                          <span className="required">Month</span>
                        </label>
                        <Form.Select
                          value={values["month"]}
                          className="form-control"
                          name="month"
                          onChange={handleChange}
                          isValid={touched.month && !errors.month}
                          isInvalid={touched.month && errors.month}
                        >
                          <option></option>
                          <option value={"01"}>01</option>
                          <option value={"02"}>02</option>
                          <option value={"03"}>03</option>
                          <option value={"04"}>04</option>
                          <option value={"05"}>05</option>
                          <option value={"06"}>06</option>
                          <option value={"07"}>07</option>
                          <option value={"08"}>08</option>
                          <option value={"09"}>09</option>
                          <option value={"10"}>10</option>
                          <option value={"11"}>11</option>
                          <option value={"12"}>12</option>
                        </Form.Select>
                        <ErrorMessage
                          name="month"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-6">
                        <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2 ">
                          <span className="required">Year</span>
                        </label>
                        <Form.Select
                          value={values["year"]}
                          className="form-control"
                          name="year"
                          onChange={handleChange}
                          isValid={touched.year && !errors.year}
                          isInvalid={touched.year && errors.year}
                        >
                          <option></option>

                          {years.map((item, key) => (
                            <option value={item} key={key}>
                              {item}
                            </option>
                          ))}
                        </Form.Select>
                        <ErrorMessage
                          name="year"
                          component="div"
                          className="invalid-feedback"
                        />

                        <div className="fv-plugins-message-container invalid-feedback" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 fv-row fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                      <span className="required">CVV</span>
                    </label>

                    <div className="position-relative">
                      <Field
                        name="cvv"
                        type="text"
                        className={
                          "form-control" +
                          (errors.cvv && touched.cvv ? " is-invalid" : "") +
                          (!errors.cvv && touched.cvv ? " is-valid" : "")
                        }
                        autoComplete="none"
                        as={InputMask}
                        mask="999"
                        formatChars={{
                          9: "[0-9]",
                        }}
                      />
                      <ErrorMessage
                        name="cvv"
                        component="div"
                        className="invalid-feedback"
                      />
                      <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                        <span className="svg-icon svg-icon-2hx">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M22 7H2V11H22V7Z" fill="currentColor" />
                            <path
                              opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-sm btn-default-200" onClick={onHide}>
                Cancel
              </Button>
              <Button className="btn btn-sm btn-primary-200" type="submit">
                Pay
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default PaymentWithStripe;

import React, { useContext, useState } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "../../UserProfile";
import {
  BsFillCalendarCheckFill,
  BsEnvelopeOpen,
  BsCart3,
} from "react-icons/bs";
import { MdSpaceDashboard } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { logout } from "../../../redux/actions/auth.actions";
import MediaQuery from "react-responsive";
import { Dropdown } from "react-bootstrap";
import languageContext from "../../../helpers/Context/lang-context";
import currencyContext from "../../../helpers/Context/currency-context";
import defaultLogo from "../../../media/shop_logo.png";
import CurrencyModal from "../../../components/CurencyModal";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <>
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="d-md-flex   justify-content-center align-items-center cursor-pointer m-2"
    >
      {children}
    </div>
  </>
));
const NavBar = () => {
  const lang = useContext(languageContext);
  const devis = useContext(currencyContext);
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.authReducer.isAuth);
  const cart = useSelector((state) => state.cartReducer.cart);
  const store_data = useSelector(
    (state) => state.domainReducer.domain[0]?.store
  );
  const [showCurencyModal, setshowCurencyModal] = useState(false);
  function languageCode(id) {
    switch (id) {
      case 1:
        return "EN";

      case 2:
        return "FR";

      case 3:
        return "AR";

      default:
        break;
    }
  }
  const currencyList = [
    {
      id: 1,
      name: "US dollar",
      code: `USD`,
    },
    {
      id: 2,
      name: "Euro",
      code: `EURO`,
    },
    {
      id: 3,
      name: "Dinar tunisien",
      code: `TND`,
    },
  ];
  function currencyCode(id) {
    switch (id) {
      case 1:
        return "USD";

      case 2:
        return "EURO";

      case 3:
        return "TND";

      default:
        break;
    }
  }
  function changeLanguage(id) {
    lang.changeLanguage(id);
  }
  function changeCurrency(id) {
    devis.changeCurrency(id);
  }
  return (
    <>
      <MediaQuery maxWidth={991}>
        <Navbar
          bg="white"
          expand="sm"
          style={{
            width: "100%",
            position: "sticky",
            top: "0",
            zIndex: "3",

            borderBottom: "2px solid #EFF2F5 ",
          }}
        >
          <Container fluid>
            <Navbar.Brand>
              <a href="/" title="Logo">
                <img
                  alt="Logo"
                  src={
                    store_data.default_image
                      ? store_data.default_image
                      : defaultLogo
                  }
                  className="cursor-pointer"
                  width="40px"
                  height="40px"
                />
              </a>
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              <div className="header header-fixed">
                <div className="container-xxl d-flex align-items-center flex-lg-stack">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-light fw-bolder"
                      onClick={() => setshowCurencyModal(true)}
                    >
                      {currencyCode(devis.currencyId)}
                    </button>
                    {showCurencyModal && (
                      <CurrencyModal
                        show={showCurencyModal}
                        onHide={() => setshowCurencyModal(false)}
                        changeCurrency={changeCurrency}
                        currencyList={currencyList}
                      />
                    )}
                    {/* <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-autoclose-true"
                      >
                        <div className=" ">
                          <span className="symbol-label  fw-bolder ">
                            {currencyCode(devis.currencyId)}
                          </span>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="menu-item ">
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => changeCurrency(1)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">USD</span>
                              <span className="menu-title fs-7">US dollar</span>
                            </span>
                          </Dropdown.Item>
                          <div className="separator my-2" />
                          <Dropdown.Item
                            eventKey="0"
                            onClick={() => changeCurrency(2)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">EURO</span>
                              <span className="menu-title fs-7 ">Euro</span>
                            </span>
                          </Dropdown.Item>

                          <div className="separator my-2" />

                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => changeCurrency(3)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">TND</span>
                              <span className="menu-title fs-7">
                                Tunisian dinar
                              </span>
                            </span>
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        as={CustomToggle}
                        // id="dropdown-autoclose-outside"
                      >
                        <div className="symbol symbol-30px  ms-lg-3 ">
                          <span className="symbol-label  fw-bolder  bg-light-warning">
                            {languageCode(lang.languageId)}
                          </span>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="menu-item ">
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => changeLanguage(1)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">EN</span>
                              <span className="menu-title fs-7">English</span>
                            </span>
                          </Dropdown.Item>
                          <div className="separator my-2" />
                          <Dropdown.Item
                            eventKey="0"
                            onClick={() => changeLanguage(2)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">FR</span>
                              <span className="menu-title fs-7 ">Français</span>
                            </span>
                          </Dropdown.Item>

                          <div className="separator my-2" />

                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => changeLanguage(3)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">AR</span>
                              <span className="menu-title fs-7">Arabe</span>
                            </span>
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <h6
                      style={{
                        color: "#e9ebf0",
                        background: "#e9ebf0",
                        height: "15px",
                        width: "1px",
                        margin: "0 8px 0 10px",
                      }}
                    ></h6>
                    <Link to="/cart">
                      <div className="symbol symbol-40px cursor-pointer ms-lg-3  ">
                        <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                          <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                            <BsCart3 size="20px" color="B5B5C3" />{" "}
                          </Button>
                        </div>
                        <span className="symbol-badge badge badge-circle bg-warning start-100">
                          {cart.length}
                        </span>
                      </div>
                    </Link>
                    {/* <h6
                  style={{
                    color: "#e9ebf0",
                    background: "#e9ebf0",
                    height: "15px",
                    width: "1px",
                    margin: "0 0 0 10px",
                  }}
                ></h6> */}
                    {/* <Link to="/node/messaging/inbox">
                  <div className="d-flex align-items-center ms-lg-3  ">
                    <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                      <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                        <BsEnvelopeOpen size="20px" color="B5B5C3" />
                      </Button>
                    </div>
                  </div>
                </Link> */}
                    {/* <h6
                  style={{
                    color: "#e9ebf0",
                    background: "#e9ebf0",
                    height: "15px",
                    width: "1px",
                    margin: "0 0 0 10px",
                  }}
                ></h6> */}
                    {/* <Link to="/calendar">
                  <div className="d-flex align-items-center ms-lg-3  ">
                    <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                      <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                        <BsFillCalendarCheckFill size="20px" color="B5B5C3" />
                      </Button>
                    </div>
                  </div>
                </Link> */}

                    {/* {isAuth ? (
                  <>
                    <h6
                      style={{
                        color: "#e9ebf0",
                        background: "#e9ebf0",
                        height: "15px",
                        width: "1px",
                        margin: "0 8px 0 10px",
                      }}
                    ></h6>
                    <Link to="/dash">
                      <div className="d-flex align-items-center ms-lg-3  ">
                        <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                          <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                            <MdSpaceDashboard size="20px" color="B5B5C3" />
                          </Button>
                        </div>
                      </div>
                    </Link>
                  </>
                ) : null} */}
                    <h6
                      style={{
                        color: "#e9ebf0",
                        background: "#e9ebf0",
                        height: "15px",
                        width: "1px",
                        margin: "0 8px 0 10px",
                      }}
                    ></h6>

                    <div className="d-flex align-items-center ms-1 ms-lg-3">
                      <div className="">
                        <UserProfile isAuth={isAuth} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Navbar>
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <Navbar
          bg="white"
          expand="lg"
          style={{
            padding: " 0px 20px",
            width: "100%",
            position: "sticky",
            top: "0",
            zIndex: "3",

            borderBottom: "2px solid #EFF2F5 ",
          }}
        >
          <Container fluid>
            <Navbar.Brand>
              <a href="/" title="Logo">
                <img
                  alt="Logo"
                  src={
                    store_data.default_image
                      ? store_data.default_image
                      : defaultLogo
                  }
                  className="cursor-pointer"
                  width="80px"
                  height="80px"
                />
              </a>
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              <div className="header header-fixed">
                <div className="container-xxl d-flex align-items-center flex-lg-stack">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn  btn-sm btn-light "
                      onClick={() => setshowCurencyModal(true)}
                    >
                      <span className=" fw-bolder">
                        {currencyCode(devis.currencyId)}
                      </span>
                    </button>
                    {showCurencyModal && (
                      <CurrencyModal
                        show={showCurencyModal}
                        onHide={() => setshowCurencyModal(false)}
                        changeCurrency={changeCurrency}
                        currencyList={currencyList}
                      />
                    )}
                    {/* <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-autoclose-true"
                      >
                        <div className=" ">
                          <span className="symbol-label  fw-bolder  ">
                          {currencyCode(devis.currencyId)}
                          </span>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="menu-item ">
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => changeCurrency(1)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">USD</span>
                              <span className="menu-title fs-7">US dollar</span>
                            </span>
                          </Dropdown.Item>
                          <div className="separator my-2" />
                          <Dropdown.Item
                            eventKey="0"
                            onClick={() => changeCurrency(2)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">EURO</span>
                              <span className="menu-title fs-7 ">Euro</span>
                            </span>
                          </Dropdown.Item>

                          <div className="separator my-2" />

                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => changeCurrency(3)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">TND</span>
                              <span className="menu-title fs-7">Tunisian dinar</span>
                            </span>
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                     */}
                    <Dropdown autoClose="outside">
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-autoclose-true"
                      >
                        <div className="symbol symbol-30px  ms-lg-3 ">
                          <span className="symbol-label  fw-bolder  bg-light-warning">
                            {languageCode(lang.languageId)}
                          </span>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="menu-item ">
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => changeLanguage(1)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">EN</span>
                              <span className="menu-title fs-7">English</span>
                            </span>
                          </Dropdown.Item>
                          <div className="separator my-2" />
                          <Dropdown.Item
                            eventKey="0"
                            onClick={() => changeLanguage(2)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">FR</span>
                              <span className="menu-title fs-7 ">Français</span>
                            </span>
                          </Dropdown.Item>

                          <div className="separator my-2" />

                          <Dropdown.Item
                            eventKey="2"
                            onClick={() => changeLanguage(3)}
                          >
                            <span className="menu-link">
                              <span className="menu-icon">AR</span>
                              <span className="menu-title fs-7">Arabe</span>
                            </span>
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <h6
                      style={{
                        color: "#e9ebf0",
                        background: "#e9ebf0",
                        height: "15px",
                        width: "1px",
                        margin: "0 0 0 8px",
                      }}
                    ></h6>
                    <Link to="/cart">
                      <div className="symbol symbol-40px cursor-pointer ms-lg-3  ">
                        <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                          <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                            <BsCart3 size="20px" color="B5B5C3" />{" "}
                          </Button>
                        </div>
                        <span className="symbol-badge badge badge-circle bg-warning start-100">
                          {cart.length}
                        </span>
                      </div>
                    </Link>
                    {/* <h6
                  style={{
                    color: "#e9ebf0",
                    background: "#e9ebf0",
                    height: "15px",
                    width: "1px",
                    margin: "0 0 0 10px",
                  }}
                ></h6> */}
                    {/* <Link to="/node/messaging/inbox">
                  <div className="d-flex align-items-center ms-lg-3  ">
                    <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                      <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                        <BsEnvelopeOpen size="20px" color="B5B5C3" />
                      </Button>
                    </div>
                  </div>
                </Link> */}
                    {/* <h6
                  style={{
                    color: "#e9ebf0",
                    background: "#e9ebf0",
                    height: "15px",
                    width: "1px",
                    margin: "0 0 0 10px",
                  }}
                ></h6> */}
                    {/* <Link to="/calendar">
                  <div className="d-flex align-items-center ms-lg-3  ">
                    <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                      <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                        <BsFillCalendarCheckFill size="20px" color="B5B5C3" />
                      </Button>
                    </div>
                  </div>
                </Link> */}

                    {/* {isAuth ? (
                  <>
                    <h6
                      style={{
                        color: "#e9ebf0",
                        background: "#e9ebf0",
                        height: "15px",
                        width: "1px",
                        margin: "0 8px 0 10px",
                      }}
                    ></h6>
                    <Link to="/dash">
                      <div className="d-flex align-items-center ms-lg-3  ">
                        <div className="position-relative btn btn-color-gray-800 btn-icon btn-active-light-warning w-30px h-30px w-md-40px h-md-40px">
                          <Button bsPrefix="btn btn-icon btn-sm btn-clean btn-text-dark-75">
                            <MdSpaceDashboard size="20px" color="B5B5C3" />
                          </Button>
                        </div>
                      </div>
                    </Link>
                  </>
                ) : null} */}
                    <h6
                      style={{
                        color: "#e9ebf0",
                        background: "#e9ebf0",
                        height: "15px",
                        width: "1px",
                        margin: "0 8px 0 10px",
                      }}
                    ></h6>

                    <div className="d-flex align-items-center ms-1 ms-lg-3">
                      <div className="">
                        <UserProfile isAuth={isAuth} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Navbar>
      </MediaQuery>
    </>
  );
};

export default NavBar;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Spinner, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { signUp } from "../../redux/actions/auth.actions";
import { v4 as uuidv4 } from "uuid";
import NavBar from "../Layout/NavBar/NavBar";
import MediaQuery from "react-responsive";

const SignUp = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const loading = useSelector((state) => state.authReducer.loading);
  const isAuth = useSelector((state) => state.authReducer.isAuth);
  const domain = useSelector((state) => state.domainReducer.domain[0]);

  const dispatch = useDispatch();
  const [is_corporate, setIs_corporate] = useState(1);
  const error_Message = "Required !";
  const store_data = useSelector(
    (state) => state.domainReducer.domain[0]?.store
  );

  const register = (path, navigate, fields) => {
    return path
      ? dispatch(signUp(fields, path, navigate))
      : dispatch(signUp(fields, null, navigate));
  };

  return (
    <div className="d-flex flex-column flex-root">
      <NavBar />
      <div className=" d-flex flex-row-fluid position-relative  mt-20 ">
        <div className="d-flex flex-center flex-column flex-column-fluid pt-20">
          <div className="w-lg-600px  bg-body rounded shadow-sm p-10 p-lg-15 mx-auto ">
            <Formik
              {...props}
              initialValues={{
                organization_id: domain ? domain.organization_id : "",
                is_corporate: 1,
                company_name: "",
                firstname: "",
                lastname: "",
                username: "",
                email: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                company_name:
                  is_corporate == 1
                    ? Yup.string().required(`${error_Message}`)
                    : null,
                firstname:
                  is_corporate == 2
                    ? Yup.string().required(`${error_Message}`)
                    : null,
                lastname:
                  is_corporate == 2
                    ? Yup.string().required(`${error_Message}`)
                    : null,
                is_corporate: Yup.string().required(`${error_Message}`),
                username: Yup.string().required(`${error_Message}`),
                email: Yup.string().required(`${error_Message}`),
                password: Yup.string().required(`${error_Message}`),
              })}
              onSubmit={(fields) => {
                if (is_corporate == 1) {
                  fields = {
                    ...fields,
                    firstname: "",
                    lastname: "",
                  };
                } else {
                  fields = { ...fields, company_name: "" };
                }
                register(location.state, navigate, fields);
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-10 text-center cursor-pointer">
                    <h1 className="text-dark mb-3">Créer un compte</h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      Vous avez déjà un compte?
                      <a
                        className="link-warning fw-bolder"
                        onClick={() => navigate("/signin")}
                      >
                        Se connecter ici
                      </a>
                    </div>
                  </div>
                  <Row className="mb-5 ">
                    <Form.Group as={Col} className="mb-2 col-12">
                      <Form.Label>Type </Form.Label>
                      <Form.Select
                        value={values["is_corporate"]}
                        className="form-control form-select-solid select2-hidden-accessible"
                        name="is_corporate"
                        isValid={touched.is_corporate && !errors.is_corporate}
                        isInvalid={touched.is_corporate && errors.is_corporate}
                        onChange={(e) => (
                          handleChange(e), setIs_corporate(e.target.value)
                        )}
                      >
                        <option value={1} key={"key"}>
                          Entreprise
                        </option>
                        <option value={2} key={"key1"}>
                          Client individuel
                        </option>
                      </Form.Select>
                      <ErrorMessage
                        name="IsCorporate"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Form.Group>
                  </Row>
                  {is_corporate == 1 ? (
                    <div className="form-group mb-5 col-12">
                      <label htmlFor="company_name">Nom de l’entreprise</label>
                      <Field
                        type="text"
                        value={values["company_name"]}
                        placeholder=""
                        name="company_name"
                        autoComplete="off"
                        className={
                          "form-control form-control-lg form-control-solid" +
                          (errors.company_name && touched.company_name
                            ? " is-invalid"
                            : "") +
                          (!errors.company_name && touched.company_name
                            ? " is-valid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="company_name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  ) : (
                    <>
                      <MediaQuery maxWidth={991}>
                        <div className="form-group mb-5 col-12">
                          <label htmlFor="firstname">Prénom</label>
                          <Field
                            type="text"
                            value={values["firstname"]}
                            placeholder=""
                            name="firstname"
                            autoComplete="off"
                            className={
                              "form-control form-control-lg form-control-solid" +
                              (errors.firstname && touched.firstname
                                ? " is-invalid"
                                : "") +
                              (!errors.firstname && touched.firstname
                                ? " is-valid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="firstname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group mb-5 col-12">
                          <label htmlFor="lastname">Nom</label>
                          <Field
                            type="text"
                            placeholder=""
                            value={values["lastname"]}
                            name="lastname"
                            autoComplete="off"
                            className={
                              "form-control form-control-lg form-control-solid" +
                              (errors.lastname && touched.lastname
                                ? " is-invalid"
                                : "") +
                              (!errors.lastname && touched.lastname
                                ? " is-valid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="lastname"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </MediaQuery>
                      <MediaQuery minWidth={992}>
                        <Row>
                          <div className="form-group col-md-6 col-6 mb-5">
                            <label htmlFor="firstname">Prénom</label>
                            <Field
                              type="text"
                              value={values["firstname"]}
                              placeholder=""
                              name="firstname"
                              autoComplete="off"
                              className={
                                "form-control form-control-lg form-control-solid" +
                                (errors.firstname && touched.firstname
                                  ? " is-invalid"
                                  : "") +
                                (!errors.firstname && touched.firstname
                                  ? " is-valid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="firstname"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group mb-5 col-md-6 col-6">
                            <label htmlFor="lastname">Nom</label>
                            <Field
                              type="text"
                              placeholder=""
                              value={values["lastname"]}
                              name="lastname"
                              autoComplete="off"
                              className={
                                "form-control form-control-lg form-control-solid" +
                                (errors.lastname && touched.lastname
                                  ? " is-invalid"
                                  : "") +
                                (!errors.lastname && touched.lastname
                                  ? " is-valid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="lastname"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Row>
                      </MediaQuery>
                    </>
                  )}
                  <div className="form-group mb-5 col-12">
                    <label htmlFor="username">Email</label>
                    <Field
                      name="email"
                      type="text"
                      value={values["email"]}
                      className={
                        "form-control form-control-lg form-control-solid" +
                        (errors.email && touched.email ? " is-invalid" : "") +
                        (!errors.email && touched.email ? " is-valid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group mb-5 col-12">
                    <label htmlFor="username">Nom d’utilisateur</label>
                    <Field
                      name="username"
                      type="text"
                      value={values["username"]}
                      className={
                        "form-control form-control-lg form-control-solid" +
                        (errors.username && touched.username
                          ? " is-invalid"
                          : "") +
                        (!errors.username && touched.username
                          ? " is-valid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group mb-5 col-12">
                    <label htmlFor="password"> Mot de passe</label>
                    <Field
                      name="password"
                      type="password"
                      value={values["password"]}
                      className={
                        "form-control form-control-lg form-control-solid" +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "") +
                        (!errors.password && touched.password
                          ? " is-valid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="text-center mt-10 ">
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn rounded-4 btn-warning px-8 py-4 my-3 mr-3"
                    >
                      {loading ? (
                        <>
                          <Spinner
                            className="mr-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Enregistrement en cours ...</span>
                        </>
                      ) : (
                        <span>S’enregistrer</span>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import React from "react";

const ErrorKey = () => {
  return (
    <div className=" d-flex flex-row-fluid bgi-size-cover bgi-position-center mt-20">
      <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12 justify-content-center">
        <h1 className="fs-2x fw-boldest text-info mt-10 mt-md-0 mb-12">
          Oops!
        </h1>
        <p className="fw-boldest display-4">Something went wrong here.</p>
        <p className="fs-3 fw-boldest mt-5">Error loading key !</p>
        <p className="fs-3">
          Your store must have an Api key!!.
          <br />
          Do you want to check the parameters of your store
        </p>
        <div className="d-flex ">
          <button
            onClick={() => window.location.replace("/")}
            className="btn btn-primary m-20 "
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorKey;

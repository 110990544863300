import React, { Component } from "react";
import MediaQuery from "react-responsive";

class CustomerInfo extends Component {
  render() {
    const customer = this.props.customer ? this.props.customer : {};
    return (
      <>
       <MediaQuery minWidth={576}>

      <div className="card card-flush  shadow-sm py-4 flex-row-fluid">
        <div className="card-header">
          <div className="card-title">
            <h2>Customer Details</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          {customer ? (
            <div className="table-responsive">
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-bold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                              fill="black"
                            ></path>
                            <path
                              d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        Customer
                      </div>
                    </td>
                    <td className="fw-bolder ">
                      {customer.is_corporate == 1 ? (
                        <span>{customer.company_name}</span>
                      ) : (
                        <span>
                          {customer.firstname} {customer.lastname}{" "}
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                              fill="black"
                            ></path>
                            <path
                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        Email
                      </div>
                    </td>
                    <td className="fw-bolder ">{customer.email}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                              fill="black"
                            ></path>
                            <path
                              opacity="0.3"
                              d="M19 4H5V20H19V4Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        Phone
                      </div>
                    </td>
                    <td className="fw-bolder ">{customer.mobile}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="overlay overlay-block card-rounded h-100">
              <div className="overlay-wrapper p-5">
                <div className="table-responsive">
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-bold text-gray-600">
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-2 me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            Customer
                          </div>
                        </td>
                        <td className="fw-bolder text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <a
                              href="/metronic8/demo18/../demo18/apps/ecommerce/customers/details.html"
                              className="text-gray-600 text-hover-primary"
                            >
                              {customer.company_name}
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-2 me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            Email
                          </div>
                        </td>
                        <td className="fw-bolder text-end">
                          <a
                            href="/metronic8/demo18/../demo18/apps/user-management/users/view.html"
                            className="text-gray-600 text-hover-primary"
                          ></a>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-2 me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                  fill="black"
                                ></path>
                                <path
                                  opacity="0.3"
                                  d="M19 4H5V20H19V4Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            Phone
                          </div>
                        </td>
                        <td className="fw-bolder text-end"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="overlay-layer card-rounded bg-dark bg-opacity-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
       </MediaQuery>
       <MediaQuery maxWidth={575}>

      <div className="card card-flush  shadow-sm py-4 flex-row-fluid">
        <div className="card-header">
          <div className="card-title">
            <h2>Customer Details</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          {customer ? (
            <div className="table-responsive">
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 ">
                <tbody className="fw-bold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                              fill="black"
                            ></path>
                            <path
                              d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        Customer
                      </div>
                    </td>
                    <td className="fw-bolder ">
                      {customer.is_corporate == 1 ? (
                        <span>{customer.company_name}</span>
                      ) : (
                        <span>
                          {customer.firstname} {customer.lastname}{" "}
                        </span>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                              fill="black"
                            ></path>
                            <path
                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        Email
                      </div>
                    </td>
                    <td className="fw-bolder ">{customer.email}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                              fill="black"
                            ></path>
                            <path
                              opacity="0.3"
                              d="M19 4H5V20H19V4Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        Phone
                      </div>
                    </td>
                    <td className="fw-bolder ">{customer.mobile}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="overlay overlay-block card-rounded h-100">
              <div className="overlay-wrapper p-5">
                <div className="table-responsive">
                  <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-bold text-gray-600">
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-2 me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            Customer
                          </div>
                        </td>
                        <td className="fw-bolder text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <a
                              href="/metronic8/demo18/../demo18/apps/ecommerce/customers/details.html"
                              className="text-gray-600 text-hover-primary"
                            >
                              {customer.company_name}
                            </a>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-2 me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            Email
                          </div>
                        </td>
                        <td className="fw-bolder text-end">
                          <a
                            href="/metronic8/demo18/../demo18/apps/user-management/users/view.html"
                            className="text-gray-600 text-hover-primary"
                          ></a>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-muted">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-2 me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                  fill="black"
                                ></path>
                                <path
                                  opacity="0.3"
                                  d="M19 4H5V20H19V4Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            Phone
                          </div>
                        </td>
                        <td className="fw-bolder text-end"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="overlay-layer card-rounded bg-dark bg-opacity-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
       </MediaQuery>
      </>
    );
  }
}

export default CustomerInfo;

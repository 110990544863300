import React from 'react'
import { useNavigate } from 'react-router'
import paymentFailed from '../../../media/payment_failed.jpg'
const PaymentFailed = () => {
    const navigate=useNavigate()
  return (
    <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-center flex-column-fluid p-10">
          <img
            src={paymentFailed}
            alt=""
            className="mw-100 mb-10 h-lg-450px"
          />
        
          <a
            onClick={() => navigate("/")}
            className="btn btn-primary"
          >
            Go to home
          </a>
        </div>
      </div>
  )
}

export default PaymentFailed
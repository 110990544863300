import Cookies from "js-cookie";
import api from "../../api";
import microCustomers from "../../api/microCustomers";
import microSetting from "../../api/microSetting";
import { alertError, alertSuccess } from "../../helpers/generalFunction";
import { removeUserInfos, setUserInfos } from "../../helpers/user.cookie";
import { renderToast } from "../../helpers/utils/Toast";
import {
  GET_CURRENT_USER,
  GET_CURRENT_USER_FAILED,
  GET_CURRENT_USER_SUCCESS,
  USER_LOGOUT,
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_UP,
  USER_SIGN_UP_FAILED,
  USER_SIGN_UP_SUCCESS,
} from "../constants/auth.constants";

export const signIn =
  (path, navigate, username, password) => async (dispatch) => {
    dispatch({ type: USER_SIGN_IN });

    const options = {
      headers: {
        Accept: "application/json",
        withCredentials: true,
      },
    };

    // microCustomers
    //   .get("sanctum/csrf-cookie", options)
    //   .then(async () => {
    try {
      const response = await microCustomers.post(
        "customers/login",
        { username: username, password: password },
        options
      );
      renderToast(response.data.message, "success");

      setUserInfos(
        response.data.user,
        response.data.token,
        response.data.customer,
        response.data.language
      );
      if (path) {
        navigate(path);
      } else {
        navigate("/");
      }
      dispatch({ type: USER_SIGN_IN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: USER_SIGN_IN_FAILED, payload: error });
      renderToast(error.response.data.message);
    }
    // })
    // .catch((error) => {
    //   console.log(error);
    //   dispatch({ type: USER_SIGN_IN_FAILED, payload: error });
    // });
  };
export const signInWithProvider =
  (path, navigate, provider, data, setIsLoading) => async (dispatch) => {
    dispatch({ type: USER_SIGN_IN });
    setIsLoading(true);
    const options = {
      headers: {
        Accept: "application/json",
        withCredentials: true,
      },
    };

    // microCustomers
    //   .get("sanctum/csrf-cookie", options)
    //   .then(async () => {
    try {
      const response = await microCustomers.post(
        "login/" + provider,
        data,
        options
      );
      renderToast(response.data.message, "success");

      setUserInfos(
        response.data.user,
        response.data.token,
        response.data.customer,
        response.data.language
      );
      if (path) {
        navigate(path);
      } else {
        navigate("/");
      }
      dispatch({ type: USER_SIGN_IN_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: USER_SIGN_IN_FAILED, payload: error });
      renderToast(error.response.data.message);
    }
    setIsLoading(false);
    // })
    // .catch((error) => {
    //   console.log(error);
    //   dispatch({ type: USER_SIGN_IN_FAILED, payload: error });
    // });
  };

export const signUp = (fields, path, navigate) => async (dispatch) => {
  dispatch({ type: USER_SIGN_UP });

  try {
    const response = await microCustomers.post(`/customers/account`, fields);
    renderToast(response.data.message, "success");

    dispatch({
      type: USER_SIGN_UP_SUCCESS,
      payload: { customer: response.data.customer },
    });
    dispatch(signIn(path, navigate, fields.username, fields.password));
  } catch (error) {
    dispatch({ type: USER_SIGN_UP_FAILED, payload: error });
    renderToast(error.response.data.message);
  }
};

export const logout = (_history) => async (dispatch) => {
  try {
    const response = await api.post("api/customers/logout");
    _history("/");
    removeUserInfos();

    dispatch({ type: USER_LOGOUT });
    renderToast("vous êtes déconnecté.", "success");
  } catch (error) {
    console.log(error);
  }
};

export const USER_SIGN_IN = "USER_SIGN_IN"
export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS"
export const USER_SIGN_IN_FAILED = "USER_SIGN_IN_FAILED"

export const USER_SIGN_UP = "USER_SIGN_UP"
export const USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS"
export const USER_SIGN_UP_FAILED = "USER_SIGN_UP_FAILED"

export const USER_LOGOUT = "USER_LOGOUT"

export const GET_CURRENT_USER = "GET_CURRENT_USER"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER_FAILED = "GET_CURRENT_USER_FAILED"

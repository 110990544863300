import React from 'react'
import { useNavigate } from 'react-router-dom'

const Unauthenticated = () => {
  const navigate=useNavigate()
  return (
    <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-center flex-column-fluid p-10">
          <img
            src="https://www.ionos.fr/digitalguide/fileadmin/DigitalGuide/Teaser/401-Unauthorized-t.jpg"
            alt=""
            className="mw-100 mb-10 h-lg-450px"
          />
          <h1 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
            Unauthenticated
          </h1>
          <a
            onClick={() => navigate("/signin")}
            className="btn btn-primary"
          >
            Login here
          </a>
        </div>
      </div>
  )
}

export default Unauthenticated
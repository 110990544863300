import React from 'react'
import { FaPaperPlane } from 'react-icons/fa'
import { BsTwitter, BsInstagram, BsFacebook, BsLinkedin, BsVimeo } from 'react-icons/bs'
import { FaAngleRight } from "react-icons/fa";
import '../../assets/css/footer.css'
import { getStoreData } from '../../helpers/cookie';

const Footer = () => {
  const store= getStoreData().organization

    return (
        <>
            <footer className='footer'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                            <h5 className="text-light footer-head" style={{ fontSize: "20px" }}>{store?.company_name}</h5>
                            <p className="mt-4 fs-15">
                                Start working with Magneto that can provide everything you need to
                                generate awareness, drive traffic, connect.
                            </p>
                            <ul className="list-unstyled social-icon social mb-0 mt-4">
                                <li className="list-inline-item fs-15">
                                    <BsTwitter size={20} />
                                </li>
                                <li className="list-inline-item fs-15">
                                    <BsInstagram size={20} />

                                </li>
                                <li className="list-inline-item fs-15">
                                    <BsFacebook size={20} />

                                </li>
                                <li className="list-inline-item fs-15">
                                    <BsLinkedin size={20} />

                                </li>
                                <li className="list-inline-item fs-15">
                                    <BsVimeo size={20} />

                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 className="text-light footer-head" style={{ fontSize: "20px" }}>Company</h5>
                            <ul className="list-unstyled footer-list mt-4">
                                <li>
                                    <a href="#" className="text-foot fs-15"><FaAngleRight /> About us</a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Services
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Team
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Pricing
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Project
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Careers
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Blog
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Login
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 className="text-light footer-head" style={{ fontSize: "20px" }}>Usefull Links</h5>
                            <ul className="list-unstyled footer-list mt-4">
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Terms of Services
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Documentation
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Changelog
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-foot fs-15">
                                        <FaAngleRight /> Components
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <h5 className="text-light footer-head" style={{ fontSize: "20px" }}>Newsletter</h5>
                            <p className="mt-4 fs-15">Sign up and receive the latest tips via email.</p>
                            <form>
                                <div className="row fs-15">
                                    <div className="col-lg-12">
                                        <div className="foot-subscribe form-group">
                                            <label>
                                                Write your email <span className="text-warning">*</span>
                                            </label>
                                            <div className="bg-white pe-3 ps-1 rounded">
                                                <div className="input-group align-items-center">
                                                    <input
                                                        type="email"
                                                        placeholder="Entrez votre adresse e-mail"
                                                        aria-describedby="button-addon1"
                                                        className="form-control border-0 shadow-0"
                                                    />
                                                    <FaPaperPlane size={20} style={{ color: "#ff5e10" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </footer>
            <footer className='footer footer-bar' style={{ fontSize: "17px", color: "#c2cdd7" }}>
                <div className=" py-4">
                    <div className="container">
                        <p className="mb-0 py-2">
                            © 2022  Tous droits réservés.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
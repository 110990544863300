import Cookies from "js-cookie"
import { GET_CURRENT_USER, GET_CURRENT_USER_FAILED, GET_CURRENT_USER_SUCCESS, USER_LOGOUT, USER_SIGN_IN, USER_SIGN_IN_FAILED, USER_SIGN_IN_SUCCESS, USER_SIGN_UP, USER_SIGN_UP_FAILED, USER_SIGN_UP_SUCCESS } from "../constants/auth.constants"
const pres_cookies=process.env.REACT_APP_COOKIES_PREFIX;

const initialState = {
    loading: false,
    isAuth: Cookies.get("loggedIn"+pres_cookies) === "1" ? true : false,
    error: {},
    user: {}
}

const authReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case USER_SIGN_IN:
        case USER_SIGN_UP:
        case GET_CURRENT_USER:

            return {
                ...state,
                loading: true,
                isAuth: false,
            }

        case USER_SIGN_IN_SUCCESS:
        case USER_SIGN_UP_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuth: true,
                user: { ...payload }
                
            }

        case GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuth: true,
            };

        case USER_SIGN_IN_FAILED:
        case USER_SIGN_UP_FAILED:
        case GET_CURRENT_USER_FAILED:
            return {
                ...state,
                loading: false,
                isAuth: false,
                error: payload
            }

        case USER_LOGOUT:
            return {
                ...state,
                loading: false,
                isAuth: false
            }

        default: return state
    }
}
export default authReducer
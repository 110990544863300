import React, { Component } from "react";
import BlankImage from "../../../media/blank-image.svg";
import { withRouter } from "../../../routes/withRouter";
import _ from "lodash";
import MediaQuery from "react-responsive";
class Products extends Component {
  render() {
    const products_list = this.props.quotation_data.products;
    var total_price = 0;
    return (
      <div className="tab-pane fade active show" role="tab-panel">
        <div className="d-flex flex-column gap-7 gap-lg-10">
          <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
            <div className="card-header">
              <div className="card-title">
                <h2>Products</h2>
              </div>
            </div>
            <div className="card-body pt-0">
            <MediaQuery maxWidth={698}>
                         <>
            {!_.isEmpty(products_list) &&
                      products_list.map((item,i) => {
                        total_price += item.price * item.number;

                        return (
                                    <div className="d-flex align-items-sm-center mt-5 shadow-sm rounded-2 p-5" key={i}>
                                    <div className="symbol symbol-70px me-5 ">
                                      <span className="symbol-label">
                                        <img
                                          src={
                                            item.product.default_image
                                              ? item.product.default_image
                                              : BlankImage
                                          }
                                          className="h-75 w-75 align-self-center"
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                      <div className="flex-grow-1 me-2">
                                        <span className="text-gray-800 text-hover-primary fs-2 fw-bold">
                                        {item.product.name}
                                        </span>
                                        <div className="fw-semibold fs-3">
                                                    Price : $
                                                    <span data-kt-ecommerce-edit-order-filter="price">
                                                    {item.price}
                                                    </span>
                                                  </div>
                                      </div>
                                      <div className="flex-grow-1 me-2">
                                      <div className="fw-semibold fs-3">
                                                    Qantity : 
                                                    <span  className="fw-bold ms-3">
                                                    {item.number}
                                                    </span>
                                                  </div>
                                      <div className="fw-semibold fs-3">
                                                    Total : 
                                                    <span className="fw-bold ms-3">
                                                    {item.price * item.number}
                                              </span>
                                                  </div>
                                                  </div>
                                    </div>
                                    
                                  </div>
                                  )})}
                                   <div className="separator mt-5" />
                                  <div className="d-flex container text-center justify-content-end">
                                            <div className=" mx-5">
                                              <div className="fs-1 fw-bold ">
                                                Total :
                                              </div>
                                            </div>
                                            <div className="fs-1  text-end ">
                                              {!_.isEmpty(products_list)
                                                ? products_list.reduce(
                                                    (total, item) =>
                                                      total +
                                                      item.price * item.number,
                                                    0
                                                  )
                                                : 0}
                                              $
                                            </div>
                                          </div>
                         </>     
                                  
                                
                                
                            </MediaQuery>
                            <MediaQuery minWidth={698}>           
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                  <thead>
                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                      <th className="min-w-175px">Product</th>
                      <th className="min-w-70px text-end">Qty</th>
                      <th className="min-w-100px text-end">Unit Price</th>
                      <th className="min-w-100px text-end">Total</th>
                    </tr>
                  </thead>

                  <tbody className="fw-bold text-gray-600">
                    {!_.isEmpty(products_list) &&
                      products_list.map((item,i) => {
                        total_price += item.price * item.number;

                        return (
                          <tr key={i}>
                            <td>
                              <div className="d-flex align-items-center">
                                <a className="symbol symbol-50px">
                                  <span
                                    className="symbol-label"
                                    style={{
                                      backgroundImage: `url(${
                                        item.product.default_image
                                          ? item.product.default_image
                                          : BlankImage
                                      })`,
                                    }}
                                  ></span>
                                </a>
                                <div className="ms-5">
                                  <a className="fw-bolder text-gray-600 text-hover-primary">
                                    {item.product.name}
                                  </a>
                                  <div className="fs-7 text-muted">
                                    Delivery Date: 20/02/2022
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="text-end">{item.number}</td>
                            <td className="text-end">${item.price}</td>
                            <td className="text-end">
                              ${item.price * item.number}
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan="3" className="fs-3 text-dark text-end">
                        Grand Total
                      </td>
                      <td className="text-dark fs-3 fw-boldest text-end">
                        ${total_price}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div></MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Products);

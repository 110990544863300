import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { BiHeart } from "react-icons/bi";
import { BsCart4, BsEye } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addToCart } from "../../../../redux/actions/cart.actions";
import Rating from "../../ProductsDetails/Components/Rating";
import blankimage from "../../../../media/blank-image.svg";

const Card = ({ el }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div
      className="col-md-6 col-xxl-4 col-xs-12  justify-content-center  p-10"
      key={el.id}
    >
      <div className="card shadow-sm h-400px">
        <div className="p-2  border-bottom" style={{ height: "50% " }}>
          <img
            src={el.default_image?el.default_image:blankimage}
            className="rounded-top"
            width={"100%"}
            height={"100%"}
            alt=""
          />
        </div>
        <div className=" card-body   " style={{ height: "50%" }}>
          <div className=" d-flex justify-content-between ">
            <span
              className="fs-3  cursor-pointer "
              onClick={() => navigate(`/product/${el.id}`)}
            >
              {el.name}
            </span>

            <span className="text-muted fs-4 ">{el.product_category.name}</span>
          </div>
          <div className=" d-flex justify-content-between">
            <h3 className="mb-2 font-weight-semibold">${el.price}</h3>
            <Rating />
          </div>
          {/* <button type="button" className="btn btn-warning text-center mt-2"
        onClick={() =>  {
            dispatch(addToCart({ product:el, qte:1 }))}}>
        <BsCart4/> <span>Add to cart</span>
      </button> */}
          <ButtonGroup className="btnGroup d-flex justify-content-center mb-3 ">
            <Button
              variant="dark"
              className="me-2"
              size="sm"
              id={el.id}
              name={el.name}
              onClick={() => navigate(`/product/${el.id}`)}
            >
              <BiHeart variant="primary" size="10px" />
            </Button>
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                dispatch(addToCart({ product: el, qte: 1 }));
              }}
            >
              Add to Cart
            </Button>

            <Button
              variant="dark"
              size="sm"
              className="ms-2"
              onClick={() => navigate(`/product/${el.id}`)}
            >
              <BsEye variant="primary" size="10px" />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default Card;

import React from "react";
import { Routes, Route } from "react-router-dom";

import Dash from "./Dashboard";
import Navigation from './Navigation'
import UserOverview from "./UserOverview";
const UnderLayout = () => {
  return (
    <div>
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <Navigation />
          <div className="app-wrapper flex-column flex-row-fluid">
            <div className=" container-fluid d-flex flex-row flex-column-fluid">
              <div className="app-main flex-column flex-row-fluid">
                <Routes>
                  
                  <Route path="/" element={<UserOverview />} />
                  <Route path="dash" element={<Dash />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderLayout;

import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export const SweetAlertWarning = ({ show, handleCancel , handleConfirm , title, content , cancelBtnText , confirmBtnText, disabled }) => {
  return (
    <SweetAlert
      warning
      show={show}
      showCancel
      confirmBtnText={confirmBtnText ? confirmBtnText : 'Confirmer'}
      cancelBtnText= {cancelBtnText ? cancelBtnText : 'Annuler'}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="default"
      title= {title ? title : ''}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      disabled = {disabled}
    >
      {content ? content : 'êtes-vous sûr?'}
    </SweetAlert>
  );
};

export const SweetAlertsuccess = ({ show, handleCancel , handleConfirm , title, content , cancelBtnText , confirmBtnText, disabled }) => {
  return (
    <SweetAlert
      success
      show={show}
      showCancel
      confirmBtnText={confirmBtnText ? confirmBtnText : 'Confirmer'}
      cancelBtnText= {cancelBtnText ? cancelBtnText : 'Annuler'}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="default"
      title= {title ? title : ''}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      disabled = {disabled}
    >
      {content ? content : 'êtes-vous sûr?'}
    </SweetAlert>
  );
};
import React, { useEffect, useState } from "react";
import _ from "lodash";
import InstantSearch from "../Search/InstantSearch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import StoreText from "../StoreText";
import blankimage from "../../media/blank-image.svg";
import { getStoreData } from "../../helpers/cookie";
const LandingHeader = () => {
  const products = useSelector((state) => state.productsReducer.products);
  const navigate = useNavigate();
const store= getStoreData().organization
  return (
    <>
      <MediaQuery maxWidth={991}>
        <section
          className="d-flex justify-content-center flex-wrap w-100 h-auto"
          style={{ backgroundColor: "#fff7ef" }}
        >
          <div className="mt-20">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div className="mb-20 text-center mw-800px p-10">
                <div className="d-flex justify-content-center">
                  <h2
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      color: "#212E44",
                    }}
                    className="me-5"
                  >
                    Boutique en ligne officielle{" "}
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "800",
                        background: "-webkit-linear-gradient(#f3a917, #f07430)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                     {store?.company_name}
                    </span>
                  </h2>
                </div>
                <p className="mt-5" style={{ fontSize: "initial" }}>
                  <StoreText
                    reference="Title1"
                    text=" Le monde change de Paradigme, nous vivons des mutations sociales, sociétales économiques et financières sans précédent ; Les Entreprises, les Lieux de vies font face aux défis de la révolution digitale et de la transformation du commerce, portés par de nouvelles technologies créatrices d'interactions inédites, nous incitant à repenser l'expérience client et imaginer le Territoire, le Commerce de façon plus ludique, plus innovant au travers d'un Urbanisme positif, optimisé, nativement connecté."
                  />
                </p>
              </div>
              <InstantSearch />
            </div>
          </div>
          <div className="mt-20 d-flex flex-column align-items-center">
            <h4
              style={{
                fontSize: "20px",
                fontWeight: "600",
                color: "chocolate",
                lineHeight: "16px",
              }}
              className="mb-10"
            >
              Les produits les plus vendus
            </h4>
            <div className="row justify-content-center ">
              {products &&
                products.slice(0, 3).map((el, i) => (
                  <div className="col-lg-4 col-md-4  mb-4" key={i}>
                    <div
                      className="card  overlay overflow-hidden "style={{minWidth: 300}}
                      onClick={() => navigate(`/product/${el.id}`)}
                    >
                      <div className="card-body p-0">
                        <div className="overlay-wrapper">
                          <img
                            src={el.default_image?el.default_image:blankimage}
                            alt=""
                            className="w-100 h-200px p-2 rounded"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10 align-items-end justify-content-center">
                          <div className="d-flex  flex-center  py-5">
                            <h1
                              className="  pt-2 ms-3 mt-3 fs-2x fw-bloder"
                              style={{ color: "white" }}
                            >
                              {el.name}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <section
          className="d-flex flex-column justify-content-center flex-wrap w-100 "
          style={{ backgroundColor: "#fff7ef" }}
        >
          <div className="mt-20">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div className="mb-20 text-center mw-800px">
                <div className="d-flex justify-content-center">
                  <h2
                    style={{
                      fontSize: "35px",
                      fontWeight: "700",
                      color: "#212E44",
                    }}
                    className="me-5"
                  >
                    Boutique en ligne officielle{""}
                  
                  <div
                    style={{
                      fontSize: "35px",
                      fontWeight: "700",
                      background: "-webkit-linear-gradient(#f3a917, #f07430)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                   {store?.company_name}
                  </div>
                  </h2>
                </div>
                <p className="mt-5" style={{ fontSize: "initial" }}>
                  <StoreText
                    reference="Title1"
                    text="Le monde change de Paradigme, nous vivons des mutations sociales, sociétales économiques et financières sans précédent ; Les Entreprises, les Lieux de vies font face aux défis de la révolution digitale et de la transformation du commerce, portés par de nouvelles technologies créatrices d'interactions inédites, nous incitant à repenser l'expérience client et imaginer le Territoire, le Commerce de façon plus ludique, plus innovant au travers d'un Urbanisme positif, optimisé, nativement connecté."
                  />
                </p>
              </div>
              <InstantSearch />
            </div>
          </div>
          <div className="mt-20 d-flex flex-column align-items-center">
            <h4
              style={{
                fontSize: "20px",
                fontWeight: "600",
                color: "chocolate",
                lineHeight: "16px",
              }}
              className="mb-10"
            >
              Les produits les plus vendus
            </h4>
            <div className="row justify-content-center container">
              {products &&
                products.slice(0, 3).map((el, i) => (
                  <div className="col-lg-4 col-md-4  mb-4 " key={i}>
                    <div
                      className="card  overlay overflow-hidden "style={{minWidth: 300}}
                      onClick={() => navigate(`/product/${el.id}`)}
                    >
                      <div className="card-body p-0 ">
                        <div className="overlay-wrapper ">
                          <img
                            src={el.default_image?el.default_image:blankimage}
                            alt=""
                            className="w-100 h-200px rounded"
                          />
                        </div>
                        <div className="overlay-layer bg-dark bg-opacity-10 align-items-end justify-content-center">
                          <div className="d-flex  flex-center  py-5">
                            <h1
                              className="  pt-2 ms-3 mt-3 fs-2x fw-bloder"
                              style={{ color: "white" }}
                            >
                              {el.name}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </MediaQuery>
    </>
  );
};

export default LandingHeader;

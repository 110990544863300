import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  BsBasket3,
  BsBoxArrowRight,
  BsEnvelope,
  BsFillPersonFill,
  BsPerson,
} from "react-icons/bs";
import { BiHeart } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { getUserInfos } from "../../helpers/user.cookie";
import { logout } from "../../redux/actions/auth.actions";
import MediaQuery from "react-responsive";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <>
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="d-md-flex   justify-content-center align-items-center cursor-pointer m-2"
    >
      {children}
    </div>
  </>
));
const UserProfile = ({ isAuth }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_data = getUserInfos();
  function ActionDropdown() {
    return (
      <>
        <MediaQuery maxWidth={991}>
          <Dropdown autoClose="outside" drop="start">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-outside">
              <div className="symbol symbol-40px  ms-lg-3 ">
                <span className="symbol-label fs-2x fw-semibold text-warning bg-light-warning">
                  {user_data?.name[0].toLocaleUpperCase()}
                </span>
                <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-15px w-15px ms-n1 mt-n1" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="menu-item ">
                <Dropdown.Item eventKey="0"  onClick={()=>navigate("/profile")}>
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsPerson className="fs-1" />
                    </span>
                    <span className="menu-title fs-7 ">Mon profile</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item eventKey="1"  onClick={()=>navigate("/profile/quotations")} >
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsBasket3 className="fs-1" />
                    </span>
                    <span className="menu-title fs-7">Mes commandes</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item eventKey="2">
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsEnvelope className="fs-1" />
                    </span>
                    <span className="menu-title fs-7">Boite de réception</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item eventKey="3">
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BiHeart className="fs-1" />
                    </span>
                    <span className="menu-title fs-7">Ma liste d'envie</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item
                  eventKey="4"
                  onClick={() => dispatch(logout(navigate))}
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsBoxArrowRight className="fs-2" />
                    </span>
                    <span className="menu-title fs-7"> Se deconnecter</span>
                  </span>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </MediaQuery>
        <MediaQuery minWidth={992}>
          <Dropdown autoClose="outside">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-outside">
              <div className="symbol symbol-40px  ms-lg-3 ">
                <span className="symbol-label fs-2x fw-semibold text-warning bg-light-warning">
                  {user_data?.name[0].toLocaleUpperCase()}
                </span>
                <div className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-15px w-15px ms-n1 mt-n1" />
              </div>
              <span className="text-dark fs-8 fw-bold lh-1  mx-2 ">
                {user_data?.name}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="menu-item ">
                <Dropdown.Item eventKey="0"
                onClick={()=>navigate("/profile")}>
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsPerson className="fs-1" />
                    </span>
                    <span className="menu-title fs-7 ">Mon profile</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item eventKey="1">
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsBasket3 className="fs-1" />
                    </span>
                    <span className="menu-title fs-7">Mes commandes</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item eventKey="2">
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsEnvelope className="fs-1" />
                    </span>
                    <span className="menu-title fs-7">Boite de réception</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item eventKey="3">
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BiHeart className="fs-1" />
                    </span>
                    <span className="menu-title fs-7">Ma liste d'envie</span>
                  </span>
                </Dropdown.Item>
                <div className="separator my-2" />

                <Dropdown.Item
                  eventKey="4"
                  onClick={() => dispatch(logout(navigate))}
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      <BsBoxArrowRight className="fs-2" />
                    </span>
                    <span className="menu-title fs-7"> Se deconnecter</span>
                  </span>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </MediaQuery>
      </>
    );
  }
  return (
    <>
      {isAuth ? (
        <>{ActionDropdown()}</>
      ) : (
        <button
          className="btn btn-sm btn-warning"
          onClick={() => navigate("/signin")}
        >
          {"SignIn"}
        </button>
      )}
    </>
  );
};

export default UserProfile;

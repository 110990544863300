import React, { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeFromCart, updateQte } from "../../redux/actions/cart.actions";
import defaultimage from "../../media/13.png";
import swal from "sweetalert";
import { MdDeleteOutline } from "react-icons/md";
import EmptyCard from "../../components/EmptyCard";
import emptyCard from "../../media/empty-cart-1.jpg";
import blankimage from "../../media/blank-image.svg";

const Shopping = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.authReducer.isAuth);
  const cart = useSelector((state) => state.cartReducer.cart);
  const proceed = (path) => {
    return isAuth
      ? navigate(`/proceed/checkout`)
      : navigate("/signin", { state: `/proceed/checkout` });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" container flex-column flex-row-fluid ">
      <div className=" d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid">
          <div className="d-flex flex-column gap-7 gap-lg-10 my-5">
            <div className="flex-row-fluid ml-lg-8">
              {cart.length == 0 ? (
                <div className="card shadow-sm rounded-4  my-5">
                  <EmptyCard
                    image={emptyCard}
                    title="Your cart is empty"
                    action="Shop our products"
                    location="/products"
                  />
                </div>
              ) : (
                <div className="card card-custom gutter-b shadow-sm rounded-4 my-5">
                  <div className="card-header flex-wrap border-0 pt-6 pb-0">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder fs-2 text-dark">
                        My Shopping Cart
                      </span>
                    </h3>
                    {/* <div className="card-toolbar">
                    <div className="dropdown dropdown-inline">
                      <a
                        onClick={() => navigate("/products")}
                        className="btn btn-primary fw-bolder font-size-sm"
                      >
                        Continue Shopping
                      </a>
                    </div>
                  </div> */}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive mx-20 ">
                      <table className="table align-middle table-row-bordered">
                        <thead>
                          <tr>
                            <th className="fw-bolder">Product</th>
                            <th className=" fw-bolder">Qty</th>
                            <th className=" fw-bolder">Price</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {cart.length != 0 &&
                            cart?.map((item, i) => (
                              <tr className="align-items-center" key={i}>
                                <td className="d-flex align-items-center fw-bolder">
                                  <div className="symbol symbol-50px flex-shrink-0 mx-4 bg-light">
                                    <div className="symbol-label">
                                      <img
                                        src={
                                          item.product.default_image
                                            ? item.product.default_image
                                            : blankimage
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <a
                                    href="#"
                                    className="text-dark text-hover-primary"
                                  >
                                    {item.product.name}
                                  </a>
                                </td>
                                <td className="text-center align-middle">
                                  <div>
                                    <input
                                      type="number"
                                      name="shelf"
                                      className="form-control w-50px "
                                      min={1}
                                      placeholder="On shelf"
                                      value={item.qte}
                                      onChange={(e) =>
                                        dispatch(
                                          updateQte({
                                            id: item.product.id,
                                            qte: e.target.value,
                                          })
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="text-right align-middle fw-bolder fs-4">
                                  ${item.product.price}
                                </td>
                                <td className="text-right  align-middle">
                                  <a
                                    onClick={() =>
                                      dispatch(removeFromCart(item.product.id))
                                    }
                                    className="btn btn-danger fw-bolder fs-sm-9"
                                  >
                                    <MdDeleteOutline size={"1rem"} />
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end mx-20 ">
                      <span className="fw-bolder fs-4 mx-5">Subtotal :</span>
                      <span className="fw-bolder fs-4 ">
                        {cart.length > 0
                          ? cart.reduce(
                              (total, item) =>
                                total + item.product.price * item.qte,
                              0
                            )
                          : 0}
                        $
                      </span>
                    </div>
                  </div>
                  <div className="card-footer row ">
                    <div className="border-0 pt-10 col-md-9">
                      <form className="">
                        <div className="form-group row">
                          <div className="col-md-3 d-flex align-items-center">
                            <label className="fw-bolder">Apply Voucher</label>
                          </div>
                          <div className="col-md-7">
                            <div className="input-group w-100">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Voucher Code"
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="border-0 text-end pt-10 col-md-3 ">
                      <a
                        onClick={() => {
                          cart.length > 0 ? proceed() : swal("Empty cart!");
                        }}
                        className="btn btn-success fw-bolder px-8 "
                      >
                        Proceed to Checkout
                      </a>
                    </div>
                  </div>
                </div>
              )}
              <div className="card card-custom shadow-sm rounded-4 ">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-7">
                    <h2 className="fw-bolder text-dark fs-2 mb-0">
                      Related Products
                    </h2>
                    {/* <button
                      href="#"
                      className="btn btn-success btn-sm fs-bolder"
                    >
                      View All
                    </button> */}
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-xxl-4 col-lg-12">
                      <div className="card card-custom card-shadowless">
                        <div className="card-body p-0">
                          <div className="overlay">
                            <div className="overlay-wrapper rounded bg-light text-center">
                              <img
                                src={defaultimage}
                                alt=""
                                className="mw-100 w-200px"
                              />
                            </div>
                            <div className="overlay-layer">
                              <a
                                href="#"
                                className="btn fw-bolder btn-sm btn-primary mr-2"
                              >
                                Quick View
                              </a>
                              <a
                                href="#"
                                className="btn fw-bolder btn-sm btn-light-primary"
                              >
                                Purchase
                              </a>
                            </div>
                          </div>
                          <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                            <a
                              href="#"
                              className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                            >
                              Adidas
                            </a>
                            <span className="fs-lg">
                              Outlines keep poorly thought
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-xxl-4 col-lg-12">
                      <div className="card card-custom card-shadowless">
                        <div className="card-body p-0">
                          <div className="overlay">
                            <div className="overlay-wrapper rounded bg-light text-center">
                              <img
                                src={defaultimage}
                                alt=""
                                className="mw-100 w-200px"
                              />
                            </div>
                            <div className="overlay-layer">
                              <a
                                href="#"
                                className="btn fw-bolder btn-sm btn-primary me-2"
                              >
                                Quick View
                              </a>
                              <a
                                href="#"
                                className="btn fw-bolder btn-sm btn-light-primary"
                              >
                                Purchase
                              </a>
                            </div>
                          </div>
                          <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                            <a
                              href="#"
                              className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                            >
                              Adidas
                            </a>
                            <span className="fs-lg">
                              Outlines keep poorly thought
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-xxl-4 col-lg-12">
                      <div className="card card-custom card-shadowless">
                        <div className="card-body p-0">
                          <div className="overlay">
                            <div className="overlay-wrapper rounded bg-light text-center">
                              <img
                                src={defaultimage}
                                alt=""
                                className="mw-100 w-200px"
                              />
                            </div>
                            <div className="overlay-layer">
                              <a
                                href="#"
                                className="btn fw-bolder btn-sm btn-primary mr-2"
                              >
                                Quick View
                              </a>
                              <a
                                href="#"
                                className="btn fw-bolder btn-sm btn-light-primary"
                              >
                                Purchase
                              </a>
                            </div>
                          </div>
                          <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                            <a
                              href="#"
                              className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                            >
                              Adidas
                            </a>
                            <span className="fs-lg">
                              Outlines keep poorly thought
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shopping;

import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "../LandingPage/LandingPage";
import ProceedToCheckout from "../ProceedToCheckout";
import ProductDetails from "../Products/ProductsDetails";
import ShoppingCart from "../ShoppingCart";
import Shop from "../Shop";
import NavBar from "./NavBar/NavBar";
import Footer from "../Footer/Footer3";
import Quotation from "../Quotation";
import ProductsList from "../Products/ProductsList";
import QuotationSummary from "../Quotation/InvoiceV2";
const index = () => {
  return (
    <div className="d-flex flex-column flex-root app-root">
      <div className="app-page flex-column flex-column-fluid">
        <NavBar />
        <>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/product/:productId" element={<ProductDetails />} />
            <Route path="/products" element={<ProductsList />} />
            <Route path="/proceed/checkout" element={<ProceedToCheckout />} />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/quotations/:id" element={<QuotationSummary />} />
          </Routes>
        </>

        <Footer />
      </div>
    </div>
  );
};

export default index;

import { isObjectEmpty } from "../helpers/generalFunction";

function CustomErrorMessage({ validationErrors, field }) {
  return (
    <>
      {!isObjectEmpty(validationErrors) &&
        !isObjectEmpty(validationErrors) &&
        Object.keys(validationErrors).includes(field) && (
          <div className="text-danger mt-1">
            {validationErrors[`${field}`].map((item, index) => (
              <span key={(index + 0.251) * 0.596}>{item}</span>
            ))}
          </div>
        )}
    </>
  );
}

export default CustomErrorMessage;

import { FETCH_PRODUCTS, FETCH_PRODUCTS_FAILED, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCT_BY_ID, FETCH_PRODUCT_BY_ID_FAILED, FETCH_PRODUCT_BY_ID_SUCCESS } from "../constants/products.constant"

const initialState = {
    loading: false,
    products: [],
    product: {},
    error: {}
}

const productsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case FETCH_PRODUCTS:
        case FETCH_PRODUCT_BY_ID:
            return {
                ...state,
                loading: true
            }

        case FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                products: payload
            }

        case FETCH_PRODUCT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                product: payload
            }

        case FETCH_PRODUCTS_FAILED:
        case FETCH_PRODUCT_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: payload
            }
            
        default:
            return state
    }
}
export default productsReducer
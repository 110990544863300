import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    let navigate = useNavigate();
    let location = useLocation();
    let params = useParams();
    
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
  };
  
  return Wrapper;
};
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../api";
import microStores from "../../../api/microStores";
import Pagination from "../../../components/Pagination";
import Empty from "../../../helpers/Empty";
import { fetchProducts } from "../../../redux/actions/products.actions";
import Card from "./components/Card";
import _ from "lodash";
import { getMetaData, getStoreId } from "../../../helpers/cookie";
import { BsSearch } from "react-icons/bs";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import MediaQuery from "react-responsive";

const Index = () => {
  const [search, setSearch] = useState("");
  const metaData = getMetaData();
  const [perPage, setPerPage] = useState(10);
  const dispatch = useDispatch();
  const handlePageChange = (page_number) => {
    applyFilter(page_number);
  };
  const handlePerPageChange = (perPage) => {
    setPerPage(perPage);
    applyFilter(1, perPage);
  };
  const products = useSelector((state) => state.productsReducer.products);
  const store_id = getStoreId();

  const [showlink, setShowlink] = useState("");
  const [product_category_list, setproduct_category_list] = useState([]);
  const [categorysChecked, setcategorysChecked] = useState([]);
  function setCategories(e) {
    var t = categorysChecked;
    if (e.target.checked == true) {
      t.push(e.target.value);
      setcategorysChecked([...t]);
    } else {
      t = t.filter((item) => item != e.target.value);
      setcategorysChecked([...t]);
    }
    if (_.isEmpty(t)) {
      dispatch(fetchProducts(store_id, [], search, 1, perPage));
    }
  }

  function fetchCategories() {
    api
      .get(`api/categories`)
      .then((response) => {
        setproduct_category_list(response.data.data);
      })
      .catch((error) => console.log(error));
  }
  function handleShowLink() {
    if (showlink) {
      setShowlink("");
    } else {
      setShowlink("hover show");
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchCategories();
  }, []);
  function applyFilter(current_page = 1, par_page = "") {
    if (!par_page) {
      par_page = perPage;
    }
    dispatch(
      fetchProducts(store_id, categorysChecked, search, current_page, par_page)
    );
   
  }

  return (
    <div className=" flex-column flex-row-fluid ">
      <div className=" d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid">
          <div className="row mt-10">
            <MediaQuery minWidth={992}>
              <div className="col-lg-3 col-md-4">
                {/* <Searchbar search={search} setSearch={setSearch} /> */}
                <div className="card  flex-column  my-10 ms-10  shadow-sm rounded-4">
                  <div className="p-9">
                    <h2 className=" mb-7">Filter</h2>

                    <div
                      className="menu menu-rounded menu-column menu-title-gray-700 menu-icon-gray-400 menu-arrow-gray-400 menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-semibold "
                      data-kt-menu="true"
                    >
                      <div
                        className={
                          "menu-item menu-sub-indention menu-accordion " +
                          showlink
                        }
                        data-kt-menu-trigger="click"
                      >
                        <a
                          onClick={() => handleShowLink()}
                          className="menu-link py-3"
                        >
                          <span className="menu-title">Categories</span>
                          <span className="menu-arrow" />
                        </a>
                        <div className="menu-sub menu-sub-accordion pt-3">
                          {product_category_list &&
                            product_category_list.map((item,i) => (
                              <div className="menu-item p-2" key={i}>
                                <div className="form-check form-check-custom form-check-solid cursor-pointer">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue={item.id}
                                    onChange={(e) => setCategories(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {!_.isEmpty(categorysChecked) && (
                      <button
                        className="btn btn-success mx-5 "
                        onClick={() => applyFilter()}
                      >
                        Aplliquer la recherche
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                {/* <List search={search} /> */}
                <div className="card shadow-sm rounded-4 m-10 ">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title ">
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Nom de produit"
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => {
                            dispatch(fetchProducts(store_id, [], search));
                            setSearch("");
                          }}
                        >
                          <BsSearch />
                        </button>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="row">
                      {!_.isEmpty(products) ? (
                        products.map(
                          (el) => <Card el={el}  />
                          // <Card2 el={el} key={i} />
                        )
                      ) : (
                        <Empty
                          description={"No items."}
                          img_heigth="70px"
                          img_width="90px"
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-footer">
                    {
                      <Pagination
                        totalPages={metaData.last_page}
                        setCurrentPage={handlePageChange}
                        perPage={metaData.per_page}
                        delta={2}
                        setPerPage={handlePerPageChange}
                        currentPage={metaData.current_page}
                      />
                    }
                  </div>
                </div>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={991}>
              <div className="justify-content-center  p-10">
                <div className="card shadow-sm">
                  <div className="card-body">
                    <InputGroup className="my-3 w-75 mx-auto">
                      <Form.Control
                        placeholder="Nom de produit"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => {
                          dispatch(fetchProducts(store_id, [], search));
                          setSearch("");
                        }}
                      >
                        <BsSearch />
                      </button>
                    </InputGroup>

                    <div className="d-flex my-5 justify-content-between">
                      <h2 className=" ">Filter</h2>
                    </div>
                    <div
                      className="menu menu-rounded menu-column my-3 menu-title-gray-700 menu-icon-gray-400 menu-arrow-gray-400 menu-bullet-gray-400 menu-arrow-gray-400 menu-state-bg fw-semibold "
                      data-kt-menu="true"
                    >
                      <div
                        className={
                          "menu-item menu-sub-indention menu-accordion " +
                          showlink
                        }
                        data-kt-menu-trigger="click"
                      >
                        <a
                          onClick={() => handleShowLink()}
                          className="menu-link py-3"
                        >
                          <span className="menu-title">Categories</span>
                          <span className="menu-arrow" />
                        </a>
                        <div className="menu-sub menu-sub-accordion pt-3">
                          {product_category_list &&
                            product_category_list.map((item,i) => (
                              <div className="menu-item p-2" key={i}>
                                <div className="form-check form-check-custom form-check-solid cursor-pointer">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue={item.id}
                                    onChange={(e) => setCategories(e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {!_.isEmpty(categorysChecked) && (
                      <button
                        className="btn btn-success mx-5 "
                        onClick={() => applyFilter()}
                      >
                        Aplliquer la recherche
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {!_.isEmpty(products) ? (
                products.map(
                  (el) => <Card el={el}  />
                  // <Card2 el={el} key={i} />
                )
              ) : (
                <Empty
                  description={"No items."}
                  img_heigth="70px"
                  img_width="90px"
                />
              )}

              <div className="p-10">
                {
                  <Pagination
                    totalPages={metaData.last_page}
                    setCurrentPage={handlePageChange}
                    perPage={metaData.per_page}
                    delta={2}
                    setPerPage={handlePerPageChange}
                    currentPage={metaData.current_page}
                  />
                }
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React from 'react'
import ContentLoader from 'react-content-loader'
const LoadingPage = () => {
  return (
    <section
      className="d-flex flex-column align-items-center justify-content-around "
      style={{ backgroundColor: "white", padding: "100px 0px" }}
    >
      <div className="text-center mb-15">
        <h1
          style={{
            color: "#09316f",
            marginBottom: "20px",
          }}
        >
          Nos produits
        </h1>
      </div>
      <div className="d-flex flex-wrap  ">
      <div className="me-5 ms-5 mt-5 bg-white rounded card shadow-sm w-400px">
      <ContentLoader
                height={"100%"}
                speed={2}
                backgroundColor={"#f2f2f2"}
                foregroundColor={"#bcbec2"}
                viewBox="0 0 400 400"
              >
                {/* Only SVG shapes */}

                <rect x="0" y="5" rx="4" ry="4" width="100%" height="300" />
              </ContentLoader>
      <div style={{ padding: "15px 15px" }}>
      <ContentLoader
                height={"100%"}
                speed={2}
                backgroundColor={"#f2f2f2"}
                foregroundColor={"#bcbec2"}
                viewBox="0 0 400 100"
              >
                {/* Only SVG shapes */}

                <rect x="0" y="5" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="30" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="55" rx="4" ry="4" width="100%" height="20" />
              </ContentLoader>
       
      </div>
    </div>
      <div className="me-5 ms-5 mt-5 bg-white rounded card shadow-sm w-400px">
      <ContentLoader
                height={"100%"}
                speed={2}
                backgroundColor={"#f2f2f2"}
                foregroundColor={"#bcbec2"}
                viewBox="0 0 400 400"
              >
                {/* Only SVG shapes */}

                <rect x="0" y="5" rx="4" ry="4" width="100%" height="300" />
              </ContentLoader>
      <div style={{ padding: "15px 15px" }}>
      <ContentLoader
                height={"100%"}
                speed={2}
                backgroundColor={"#f2f2f2"}
                foregroundColor={"#bcbec2"}
                viewBox="0 0 400 100"
              >
                {/* Only SVG shapes */}

                <rect x="0" y="5" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="30" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="55" rx="4" ry="4" width="100%" height="20" />
              </ContentLoader>
       
      </div>
    </div>
      <div className="me-5 ms-5 mt-5 bg-white rounded card shadow-sm w-400px">
      <ContentLoader
                height={"100%"}
                speed={2}
                backgroundColor={"#f2f2f2"}
                foregroundColor={"#bcbec2"}
                viewBox="0 0 400 400"
              >
                {/* Only SVG shapes */}

                <rect x="0" y="5" rx="4" ry="4" width="100%" height="300" />
              </ContentLoader>
      <div style={{ padding: "15px 15px" }}>
      <ContentLoader
                height={"100%"}
                speed={2}
                backgroundColor={"#f2f2f2"}
                foregroundColor={"#bcbec2"}
                viewBox="0 0 400 100"
              >
                {/* Only SVG shapes */}

                <rect x="0" y="5" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="30" rx="4" ry="4" width="100%" height="20" />
                <rect x="0" y="55" rx="4" ry="4" width="100%" height="20" />
              </ContentLoader>
       
      </div>
    </div>
      </div>
    </section>
  )
}

export default LoadingPage
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${require("../../media/bg-3.jpg")})`,
        }}
      >
        <h1
          className="fw-boldest text-dark-75 mt-15"
          style={{ fontSize: "10rem" }}
        >
          404
        </h1>
        <p className="fs-3 text-muted fw-normal">
          OOPS! Something went wrong here
        </p>
      </div>
    </div>
  );
};

export default NotFound;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProduct } from "../../../redux/actions/products.actions";
import Advanced from "./Components/Advanced";
import General from "./Components/General";
import LoadingPage from "./Components/LoadingPage";
import Reviews from "./Components/Reviews";
import ImagesSlider from "../ImagesSlider";
import _ from "lodash";
import { addToCart } from "../../../redux/actions/cart.actions";
import MediaQuery from "react-responsive";
import defaultimage from "../../../media/13.png";
import blankimage from "../../../media/blank-image.svg";

const ProductDetails = () => {
  const { productId, organizationId, createdAt } = useParams();
  const [qte, setQte] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.productsReducer.product);
  const [visibleTab, setVisibleTab] = useState("General");
  const data = [
    {
      id: "1",
      tabTitle: "General",
    },
    {
      id: "2",
      tabTitle: "Advanced",
    },
    {
      id: "3",
      tabTitle: "Reviews",
    },
  ];

  const listTabs = () => {
    return data.map((item,i) => (
      <li className="nav-item  cursor-pointer" role="presentation" key={i}>
        <a
          className={
            visibleTab == item.tabTitle
              ? "nav-link text-active-primary pb-4 active"
              : "nav-link text-active-primary pb-4 "
          }
          onClick={() => setVisibleTab(item.tabTitle)}
        >
          {item.tabTitle}
        </a>
      </li>
    ));
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(fetchProduct(productId));
  }, []);
  return (
    <div className="container flex-column flex-row-fluid ">
      <div className=" d-flex flex-row flex-column-fluid">
        {!_.isEmpty(product) ? (
          <>
            <MediaQuery minWidth={992}>
              <div className="app-main flex-column flex-row-fluid">
                <div className=" d-flex flex-row flex-column-fluid mt-20">
                  <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-550px mb-7 me-lg-10 ">
                    <div
                      className="card card-flush py-4 h-500px"
                     
                    >
                       <img
            src={product.default_image?product.default_image:blankimage}
            className="rounded-top"
            width={"100%"}
            height={"100%"}
            alt=""
          />
                    </div>
                    <div className="card card-flush ">
                      <div className="card-header">
                        <div className="card-title">
                          <h2>En stock</h2>
                        </div>
                        <div className="card-toolbar">
                          <div className="rounded-circle w-15px h-15px bg-primary bg-primary" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                      {listTabs()}
                    </ul>

                    <div className="tab-content  ">
                      <div
                        className={
                          visibleTab == `General`
                            ? "tab-pane fade active show"
                            : "tab-pane fade "
                        }
                      >
                        <General product={product} />
                      </div>
                      <div
                        className={
                          visibleTab == `Advanced`
                            ? "tab-pane fade active show"
                            : "tab-pane fade "
                        }
                      >
                        <Advanced />
                      </div>
                      <div
                        className={
                          visibleTab == `Reviews`
                            ? "tab-pane fade active show"
                            : "tab-pane fade "
                        }
                      >
                        <Reviews />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-custom shadow-sm rounded-4 my-10 ">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-7">
                      <h2 className="fw-bolder text-dark fs-2 mb-0">
                        Related Products
                      </h2>
                      <button
                        href="#"
                        className="btn btn-success btn-sm fs-bolder"
                      >
                        View All
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-xxl-4 col-lg-12">
                        <div className="card card-custom card-shadowless">
                          <div className="card-body p-0">
                            <div className="overlay">
                              <div className="overlay-wrapper rounded bg-light text-center">
                                <img
                                  src={defaultimage}
                                  alt=""
                                  className="mw-100 w-200px"
                                />
                              </div>
                              <div className="overlay-layer">
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-primary mr-2"
                                >
                                  Quick View
                                </a>
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-light-primary"
                                >
                                  Purchase
                                </a>
                              </div>
                            </div>
                            <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                              <a
                                href="#"
                                className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                              >
                                Adidas
                              </a>
                              <span className="fs-lg">
                                Outlines keep poorly thought
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-xxl-4 col-lg-12">
                        <div className="card card-custom card-shadowless">
                          <div className="card-body p-0">
                            <div className="overlay">
                              <div className="overlay-wrapper rounded bg-light text-center">
                                <img
                                 src={defaultimage}
                                  alt=""
                                  className="mw-100 w-200px"
                                />
                              </div>
                              <div className="overlay-layer">
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-primary me-2"
                                >
                                  Quick View
                                </a>
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-light-primary"
                                >
                                  Purchase
                                </a>
                              </div>
                            </div>
                            <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                              <a
                                href="#"
                                className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                              >
                                Adidas
                              </a>
                              <span className="fs-lg">
                                Outlines keep poorly thought
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-xxl-4 col-lg-12">
                        <div className="card card-custom card-shadowless">
                          <div className="card-body p-0">
                            <div className="overlay">
                              <div className="overlay-wrapper rounded bg-light text-center">
                                <img
                                  src={defaultimage}
                                  alt=""
                                  className="mw-100 w-200px"
                                />
                              </div>
                              <div className="overlay-layer">
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-primary mr-2"
                                >
                                  Quick View
                                </a>
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-light-primary"
                                >
                                  Purchase
                                </a>
                              </div>
                            </div>
                            <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                              <a
                                href="#"
                                className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                              >
                                Adidas
                              </a>
                              <span className="fs-lg">
                                Outlines keep poorly thought
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={991}>
              <div className="app-main flex-column flex-row-fluid">
                <div className=" d-flex  flex-column mt-20 ">
                  <div className="d-flex flex-column gap-7 gap-lg-10 mb-7 m-7  ">
                    <div className="card card-flush py-4  h-400px mx-10">
                      <img
                        src={product.default_image}
                        className="h-100 w-100"
                      />
                    </div>
                    <div className="card card-flush ">
                      <div className="card-header">
                        <div className="card-title">
                          <h2>En stock</h2>
                        </div>
                        <div className="card-toolbar">
                          <div className="rounded-circle w-15px h-15px bg-primary bg-primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column  gap-7  ">
                    <div className="d-flex flex-column ">
                      <ul className="nav   nav-line-tabs nav-line-tabs-2x  fs-3 fw-bloder">
                        {listTabs()}
                      </ul>

                      <div className=" tab-content  shadow-sm rounded-bottom  min-h-auto">
                        <div
                          className={
                            visibleTab == `General`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <General product={product} />
                        </div>
                        <div
                          className={
                            visibleTab == `Advanced`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <Advanced />
                        </div>
                        <div
                          className={
                            visibleTab == `Reviews`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <Reviews />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-custom shadow-sm rounded-4 my-10 ">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-7">
                      <h2 className="fw-bolder text-dark fs-2 mb-0">
                        Related Products
                      </h2>
                      <button
                        href="#"
                        className="btn btn-success btn-sm fs-bolder"
                      >
                        View All
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-xxl-4 col-lg-12">
                        <div className="card card-custom card-shadowless">
                          <div className="card-body p-0">
                            <div className="overlay">
                              <div className="overlay-wrapper rounded bg-light text-center">
                                <img
                                  src={defaultimage}
                                  alt=""
                                  className="mw-100 w-200px"
                                />
                              </div>
                              <div className="overlay-layer">
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-primary mr-2"
                                >
                                  Quick View
                                </a>
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-light-primary"
                                >
                                  Purchase
                                </a>
                              </div>
                            </div>
                            <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                              <a
                                href="#"
                                className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                              >
                                Adidas
                              </a>
                              <span className="fs-lg">
                                Outlines keep poorly thought
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-xxl-4 col-lg-12">
                        <div className="card card-custom card-shadowless">
                          <div className="card-body p-0">
                            <div className="overlay">
                              <div className="overlay-wrapper rounded bg-light text-center">
                                <img
                                  src={defaultimage}
                                  alt=""
                                  className="mw-100 w-200px"
                                />
                              </div>
                              <div className="overlay-layer">
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-primary me-2"
                                >
                                  Quick View
                                </a>
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-light-primary"
                                >
                                  Purchase
                                </a>
                              </div>
                            </div>
                            <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                              <a
                                href="#"
                                className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                              >
                                Adidas
                              </a>
                              <span className="fs-lg">
                                Outlines keep poorly thought
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-xxl-4 col-lg-12">
                        <div className="card card-custom card-shadowless">
                          <div className="card-body p-0">
                            <div className="overlay">
                              <div className="overlay-wrapper rounded bg-light text-center">
                                <img
                                  src={defaultimage}
                                  alt=""
                                  className="mw-100 w-200px"
                                />
                              </div>
                              <div className="overlay-layer">
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-primary mr-2"
                                >
                                  Quick View
                                </a>
                                <a
                                  href="#"
                                  className="btn fw-bolder btn-sm btn-light-primary"
                                >
                                  Purchase
                                </a>
                              </div>
                            </div>
                            <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                              <a
                                href="#"
                                className="fs-4 fw-bolder text-dark-75 text-hover-primary mb-1"
                              >
                                Adidas
                              </a>
                              <span className="fs-lg">
                                Outlines keep poorly thought
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MediaQuery>
          </>
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  );
};

export default ProductDetails;

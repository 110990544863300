import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_QTE,
  REMOVE_CART,
} from "../constants/cart.constant";

const initialState = {
  loading: false,
  cart: [],
};

const cartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: state.cart.find((item) => item.product.id == payload.product.id)
          ? state.cart.map((item) =>
              item.product.id == payload.id
                ? { ...item, qte: item.qte + payload.qte }
                : item
            )
          : [...state.cart, payload],
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.product.id != payload),
      };
    case REMOVE_CART:
      return {
        ...state,
        cart: [],
      };

    case UPDATE_QTE:
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.product.id == payload.id ? { ...item, qte: payload.qte } : item
        ),
      };

    default:
      return state;
  }
};
export default cartReducer;

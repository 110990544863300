import microProducts from "../../api/microProducts";
import microStores from "../../api/microStores";
import { setMetaData, setProductsData } from "../../helpers/cookie";
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_BY_ID,
  FETCH_PRODUCT_BY_ID_FAILED,
  FETCH_PRODUCT_BY_ID_SUCCESS,
} from "../constants/products.constant";

export const fetchProducts =
  (store_id, categories = [], search = "", current_page = 1, par_page = "") =>
  async (dispatch) => {
    dispatch({ type: FETCH_PRODUCTS });
    try {
      const products = await microStores.get(
        `/stores/${store_id}/products?search=${search}&page=${current_page}&per_page=${par_page}`,
        {
          params: {
            category_ids: categories,
          },
        }
      );
      setProductsData(products.data.data);
      setMetaData(products.data.meta);
      dispatch({
        type: FETCH_PRODUCTS_SUCCESS,
        payload: products.data.data,
      });
    } catch (error) {
      dispatch({ type: FETCH_PRODUCTS_FAILED });
    }
  };

export const fetchProduct = (product_id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_BY_ID });
  try {
    const product = await microStores.get(`/stores/products/${product_id}`);
    dispatch({
      type: FETCH_PRODUCT_BY_ID_SUCCESS,
      payload: product.data.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCT_BY_ID_FAILED });
  }
};

export const fetchProductById =
  (ProductId, OrganizationId, CreatedAt) => async (dispatch) => {
    dispatch({ type: FETCH_PRODUCT_BY_ID });
    try {
      const response = await microProducts.get(
        `/products/${OrganizationId}/${ProductId}/${CreatedAt}`
      );
      dispatch({
        type: FETCH_PRODUCT_BY_ID_SUCCESS,
        payload: response.data.product,
      });
    } catch (error) {
      dispatch({ type: FETCH_PRODUCT_BY_ID_FAILED });
    }
  };

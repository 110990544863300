import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class AddModal extends React.Component {
  render() {
    const error_Message = "Required!";
    return (
      <Formik
        initialValues={{
          organization_id: this.props.organization_id,
          entity_id: this.props.entity_id,
          entity_identifier: this.props.entity_identifier,
          password: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(`${error_Message}`),
        })}
        onSubmit={(fields) => {
          this.props.onSubmit(fields);
          this.props.onHide();
        }}
      >
        {({ touched, errors }) => (
          <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add new blockchain account
              </Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                <div className="form-group mb-5">
                  <label htmlFor="password">Password</label>
                  <Field
                    name="password"
                    type="password"
                    className={
                      "form-control" +
                      (errors.password && touched.password
                        ? " is-invalid"
                        : "") +
                      (!errors.password && touched.password ? " is-valid" : "")
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-sm btn-default-200"
                  onClick={this.props.onHide}
                >
                  Cancel
                </Button>
                <Button className="btn btn-sm btn-primary-200" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default AddModal;

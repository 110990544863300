import React, { useState } from "react";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { Button, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Typeahead, withAsync } from "react-bootstrap-typeahead";
import _ from "lodash";
import microStores from "../../api/microStores";
import { getStoreId } from "../../helpers/cookie";
import emptyImage from "../../media/blank-image.svg";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
const AsyncTypeahead = withAsync(Typeahead);

const InstantSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState([]);
  const store_id = getStoreId();
  const navigate = useNavigate();
  const handleSearch = (query) => {
    setIsLoading(true);

    microStores
      .get(`stores/${store_id}/search?search=${query}`)
      .then((response) => {
        var res = response.data;

        setOptions(res.products);
        setIsLoading(false);
      });
  };
  const filterBy = () => true;
  return (
    <>
      <MediaQuery maxWidth={991}>
        <InputGroup size="sm" className="mb-1 me-0 w-75">
          <AsyncTypeahead
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey="name"
            minLength={3}
            onSearch={handleSearch}
            onChange={(e) => (e.length > 0 ? setOption(e[0]) : "")}
            options={options}
            emptyLabel="Aucun produit trouvé."
            searchText="Recherche..."
            placeholder="Rechercher un produit ..."
            renderMenuItemChildren={(option) => (
              <div className="d-flex align-items-center justify-content-around fw-bolder">
                <div className="symbol symbol-60 flex-shrink-0 me-4 bg-light">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: { emptyImage },
                    }}
                  />
                </div>
                <span className="text-dark text-hover-primary">
                  {option.name}
                </span>
                <span className=" badge badge-light-success mx-2">
                  {option.price}$
                </span>
              </div>
            )}
          />
          <Button
            variant="btn btn-warning"
            onClick={() =>
              !_.isEmpty(option) ? navigate(`/product/${option.id}`) : null
            }
          >
            <FaSearch />
          </Button>
        </InputGroup>
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <InputGroup size="lg" className="mb-1 me-0 w-50">
          <AsyncTypeahead
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey="name"
            minLength={3}
            onSearch={handleSearch}
            onChange={(e) => (e.length > 0 ? setOption(e[0]) : "")}
            options={options}
            emptyLabel="Aucun produit trouvé."
            searchText="Recherche..."
            placeholder="Rechercher un produit ..."
            renderMenuItemChildren={(option) => (
              <div className="d-flex align-items-center justify-content-around fw-bolder">
                <div className="symbol symbol-60 flex-shrink-0 me-4 bg-light">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: { emptyImage },
                    }}
                  />
                </div>
                <span className="text-dark text-hover-primary">
                  {option.name}
                </span>
                <span className=" badge badge-light-success mx-2">
                  {option.price}$
                </span>
              </div>
            )}
          />
          <Button
            variant="btn btn-warning"
            onClick={() =>
              !_.isEmpty(option) ? navigate(`/product/${option.id}`) : null
            }
          >
            <FaSearch /> Rechercher
          </Button>
        </InputGroup>
      </MediaQuery>
    </>
  );
};
export default InstantSearch;

import React from 'react'

const Advanced = () => {
    return (
        <div className="d-flex flex-column gap-7 gap-lg-10">
            <div className="card card-flush py-4">
                <div className="card-header">
                    <div className="card-title">
                        <h2>Advanced</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advanced
import { applyMiddleware, compose, legacy_createStore } from "redux";
import reduxThunk from "redux-thunk";
import rootReducer from "../reducers";

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
    compose;

const store = legacy_createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(reduxThunk))
);

export default store;
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { signIn } from "../../redux/actions/auth.actions";
import NavBar from "../Layout/NavBar/NavBar";
import GoogleAuth from "./GoogleAuth";

const SignIn = (props) => {
  const store_data = useSelector(
    (state) => state.domainReducer.domain[0]?.store
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.authReducer.loading);
  const isAuth = useSelector((state) => state.authReducer.isAuth);
  const error_Message = "Required !";
  const location = useLocation();

  const login = (path, navigate, UserName, userUserPassword) => {
    return path
      ? dispatch(signIn(path, navigate, UserName, userUserPassword))
      : dispatch(signIn(null, navigate, UserName, userUserPassword));
  };

  return (
    <div className="d-flex flex-column flex-root">
      <NavBar />
      <div className=" d-flex flex-row-fluid position-relative  mt-20">
        <div className="d-flex flex-center flex-column flex-column-fluid pt-20">
          <div className="w-lg-500px bg-body rounded shadow-sm p-5 p-lg-15 mx-auto">
            <Formik
              {...props}
              initialValues={{
                UserName: "",
                UserPassword: "",
              }}
              validationSchema={Yup.object().shape({
                UserName: Yup.string().required(`${error_Message}`),

                UserPassword: Yup.string().required(`${error_Message}`),
              })}
              onSubmit={(fields) => {
                login(
                  location.state,
                  navigate,
                  fields.UserName,
                  fields.UserPassword
                );
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="text-center mb-10 cursor-pointer">
                    <h1 className="text-dark mb-3">Connectez-vous </h1>
                    <div className="text-gray-400 fw-bold fs-4">
                      Nouveau ici?
                      <a
                        className="link-warning fw-bolder"
                        onClick={() =>
                          location.state
                            ? navigate("/signup", { state: location.state })
                            : navigate("/signup")
                        }
                      >
                        Créer un compte
                      </a>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <label htmlFor="UserName">Nom d’utilisateur</label>
                    <Field
                      name="UserName"
                      type="text"
                      className={
                        "form-control form-control-lg form-control-solid" +
                        (errors.UserName && touched.UserName
                          ? " is-invalid"
                          : "") +
                        (!errors.UserName && touched.UserName
                          ? " is-valid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="UserName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group mb-5">
                    <div className="d-flex flex-stack mb-2">
                      <label htmlFor="UserPassword">Mot de passe</label>
                      <a
                        className="link-warning fs-6 fw-bolder"
                        onClick={() => navigate("/forgetpassword")}
                      >
                        Mot de passe oublié ?
                      </a>
                    </div>
                    <Field
                      name="UserPassword"
                      type="password"
                      className={
                        "form-control form-control-lg form-control-solid" +
                        (errors.UserPassword && touched.UserPassword
                          ? " is-invalid"
                          : "") +
                        (!errors.UserPassword && touched.UserPassword
                          ? " is-valid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="UserPassword"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="d-flex w-100 flex-column justify-content-center align-items-center gap-4">
                    <div className="w-75">
                      <Button
                        type="submit"
                        className="btn w-100 rounded-4 btn-warning col-5  fs-5 fw-5"
                      >
                        {loading ? (
                          <>
                            <Spinner
                              className="mr-2"
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="fs-7 fw-5">
                              Connexion en cours{" "}
                            </span>
                          </>
                        ) : (
                          <span>Se connecter</span>
                        )}
                      </Button>
                    </div>
                    <div className="w-75 separator separator-content border-dark my-4">
                      <span className="text-gray-800 fw-semibold ">Ou</span>
                    </div>
                    <div className="w-75">
                      <GoogleAuth path={location.state} navigate={navigate} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* <div className="d-flex flex-center flex-column-auto p-10">
                      <div className="d-flex align-items-center fw-bold fs-6">
                          <a className="text-muted text-hover-primary px-2">
                              À propos
                          </a>
                          <a className="text-muted text-hover-primary px-2">
                              Contact
                          </a>
                          <a className="text-muted text-hover-primary px-2">
                              Nous contacter
                          </a>
                      </div>
                  </div> */}
      </div>
    </div>
  );
};

export default SignIn;

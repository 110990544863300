import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import currencyContext from "../helpers/Context/currency-context";
const CurencyModal = ({ show, onHide, currencyList, changeCurrency }) => {
  const devis = useContext(currencyContext);

  const [selectedCurrency, setselectedCurrency] = useState(devis.currencyId)
function handelClose(id) {
  console.log(id)
  setselectedCurrency(id)
  changeCurrency(id);
  onHide()
}
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      
    >
      <Modal.Header closeButton>
        <Modal.Title>Sélectionnez votre devise</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {!_.isEmpty(currencyList) &&
            currencyList.map((item) => (
              <div className="col-4" onClick={()=>handelClose(item.id)}>
                <input
                  type="radio"
                  className="btn-check"
                  name="currency"
                  defaultValue={item.id}
                  defaultChecked={selectedCurrency==item.id?"checked":""}
                 
                />
                <label
                  className="btn btn-outline btn-outline-default btn-active-light-success p-7 d-flex align-items-center mb-10"
                  htmlFor="kt_create_account_form_account_type_personal"
                >
                  <span className=" fw-semibold text-start">
                    <span className="text-dark fw-bold d-block fs-4 mb-2">
                      {item.name}
                    </span>
                    <span className="text-muted fw-semibold fs-6">
                      {item.code}
                    </span>
                  </span>
                </label>

                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CurencyModal;

import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useDispatch, useSelector } from "react-redux";
import { signInWithProvider } from "../../redux/actions/auth.actions";
import { renderToast } from "../../helpers/utils/Toast";
import { Spinner } from "react-bootstrap";
const GoogleAuth = ({ path, navigate }) => {
  const dispatch = useDispatch();
  const provider = "google";
  const loading = useSelector((state) => state.authReducer.loading);
  const [isLoading, setIsLoading] = useState(false);
  const clientId =
    process.env.REACT_APP_GOOGLE_CLIENT_ID + ".apps.googleusercontent.com";

  function onSuccessResponse(res) {
    path
      ? dispatch(
          signInWithProvider(
            path,
            navigate,
            provider,
            res.profileObj,
            setIsLoading
          )
        )
      : dispatch(
          signInWithProvider(
            null,
            navigate,
            provider,
            res.profileObj,
            setIsLoading
          )
        );
  }

  function onFailureResponse(err) {
    renderToast("Echec de la connexion");
  }
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  return (
    <GoogleLogin
      clientId={clientId}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className="btn rounded-4 w-100 btn-outline btn-outline-dark col-6 fs-5 fw-5"
        >
          {isLoading ? (
            <div className="d-flex gap-4 justify-content-center">
              <Spinner
                className="mr-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="fs-7 fw-5">Connexion en cours </span>
            </div>
          ) : (
            <>
              <FcGoogle />{" "}
              <span className="ms-5">Se connecter avec Google</span>
            </>
          )}
        </button>
      )}
      onSuccess={onSuccessResponse}
      onFailure={onFailureResponse}
    />
  );
};

export default GoogleAuth;

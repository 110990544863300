import React from "react";
import NavBar from "../Layout/NavBar/NavBar";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  return (
    <div>
      <NavBar />
      <div style={{ marginTop: "50px" }}>
        {/* <Result
          status="warning"
          title="Not created yet"
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Go to home page
            </Button>
          }
        /> */}
      </div>
    </div>
  );
};

export default Index;

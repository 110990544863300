import "./App.css";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import Layout from "./pages/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { createContext, useEffect, useState } from "react";
import { fetchDomains } from "./redux/actions/domain.actions";
import { getCurrentUser } from "./redux/actions/auth.actions";
import { getUserInfos } from "./helpers/user.cookie";
import { fetchCustomerById } from "./redux/actions/customer.actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import RestPassword from "./pages/Auth/RestPassword";
import Unauthenticated from "./pages/Erorrs/Unauthenticated";
import NotFound from "./pages/Erorrs/NotFound";
import ErrorServer from "./pages/Erorrs/ErrorServer";
import UnderMaintenance from "./pages/Erorrs/UnderMaintenance";
import _ from "lodash";
import ErrorKey from "./pages/Erorrs/ErrorKey";
import PaymentFailed from "./pages/Quotation/components/PaymentFailed";
import PaymentSucess from "./pages/Quotation/components/PaymentSucess";
import languageContext from './helpers/Context/lang-context'
import currencyContext from './helpers/Context/currency-context'
import Profile from "./pages/UserProfile/UnderLayout";
function App() {
  const loading = useSelector((state) => state.domainReducer.loading);
  const domain = useSelector((state) => state.domainReducer.domain[0]);
  const key = useSelector((state) => state.domainReducer.key);
const [language, setlanguage] = useState(1)
const changeLanguage = (id) => {
  setlanguage(id);
};
const [currency, setCurrency] = useState(1)
const changeCurrency = (id) => {
  setCurrency(id);
};
  const dispatch = useDispatch();
  const user_data = getUserInfos();
  useEffect(() => {
    dispatch(fetchDomains(window.location.origin));
    if (user_data && user_data.UserId) {
      const entityIdentifier = JSON.parse(user_data.EntityIdentifier);
      dispatch(
        fetchCustomerById(
          user_data.OrganizationId,
          entityIdentifier.CustomerId,
          entityIdentifier.CreatedAt
        )
      );
    }
  }, []);
  

  return <languageContext.Provider value={{ languageId: language, changeLanguage: changeLanguage }}> 
   <currencyContext.Provider value={{ currencyId: currency, changeCurrency: changeCurrency }}> 
  
  <>
    {loading ? (
    <div className="text-center mt-40 ">
      <h1>Veuillez attendre que le chargement soit terminé.</h1>
      <Spinner
        animation="grow"
        variant="danger"
        className="mt-80px h-100px w-100px"
      />
    </div>
  ) : (
    <>
      {key == "no acess key" ? (
        <>
          <Routes>
            <Route path="/error-key" element={<ErrorKey />} />
            <Route path="*" element={<Navigate to="/error-key" replace />} />
          </Routes>
        </>
      ) : (
        !_.isEmpty(domain) &&
        (domain.status != 1 ? (
          <>
            <Routes>
              <Route path="/*" element={<UnderMaintenance />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/forgetpassword" element={<ForgetPassword />} />
              <Route path="/restpassword" element={<RestPassword />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/401" element={<Unauthenticated />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/501" element={<ErrorServer />} />
              <Route path="/payment-failed" element={<PaymentFailed />} />
              <Route path="/payment-success" element={<PaymentSucess />} />
              <Route path="/*" element={<Layout />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/profile/*" element={<Profile />} />
            </Routes>
            <ToastContainer
              autoClose={2500}
              position="top-center"
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        ))
      )}
    </>
  )}
  </>
  </currencyContext.Provider>
  </languageContext.Provider>
  
}

export default App;

import React from 'react'
import maintenanceImg from '../../media/maintenance.png'
const UnderMaintenance = () => {

  return (  
    <div className="d-flex flex-column flex-root">
    
    <div className=" d-flex flex-row-fluid position-relative  mt-20">
  
    <div className="container d-flex flex-row-fluid  justify-content-center p-12">
        <div>
        <img
            src= {maintenanceImg}
            alt=""
            className="mw-100 mb-10 h-lg-450px"
          />
        </div>
        <div className='d-flex flex-column  justify-content-center '>
      <h1 className="fs-2x fw-boldest text-info mt-10 mt-md-0 mb-12">
        Oops!
      </h1>
      <p className="fw-boldest display-4">Maintenance Mode !</p>
      <p className='fs-3'>This site is undergoing a brief bit of maintenance.</p>
	<p className='fs-3'>We apologize for the inconvenience, we're doing our best to get things back to working order for you.</p>
      </div>
    </div>
  </div>
  </div>
   
  )
}

export default UnderMaintenance
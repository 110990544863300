import NavBar from "../Layout/NavBar/NavBar";
import React, { useEffect } from "react";
import RecentProducts from "../Products/RecentProducts";
import LandingHeader from "./LandingHeader";
import Steps from "./Steps";

const LandingPage = ({ isAuth, logout }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className=" flex-column flex-row-fluid ">
      <div className=" d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid">
          <LandingHeader isAuth={isAuth} logout={logout} />
          <RecentProducts />
          <Steps />
        </div>
      </div>
    </div>
  );
};
export default LandingPage;

import React, { useState } from "react";
import api from "../../../api";
import { renderToast } from "../../../helpers/utils/Toast";
import {
  SweetAlertsuccess,
  SweetAlertWarning,
} from "../../../helpers/utils/SweetAlertCustom";
import PaymentWithStripe from "./PaymentWithStripe";
import _ from 'lodash'
const Payments = ({ quotation_data,fetchQuotation }) => {
  const [quoteBtnLoading, setQuoteBtnLoading] = useState(false);
  const [Payment_mode, setPayment_mode] = useState("");
  const [addModalShow, setAddModalShow] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSucessAlert, setShowSucessAlert] = useState(false);
  const [message, setMessage] = useState("");
  console.log(quotation_data);
  const onSubmit = () => {};
  const successPayment = (response) => {
    console.log(response);
  };
  const failurePayment = (response) => {
    console.log(response);
  };
  const paymentWithStripe = (fields) => {
    api
      .post("api/payment/10", fields)
      .then((response) => {
        setShowSucessAlert(true);
        setMessage(response.data.message);
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setShowErrorAlert(true);
      });
  };
  const paymentWithClicToPay = () => {
    const originUrl = window.location.origin;
    let fail_url = originUrl + "/payment-failed";
    let success_url = originUrl + "/payment-success";
    api
      .post("api/payment/14", {
        amount: 100,
        fail_url: fail_url,
        success_url: success_url,
      })
      .then((response) => {
        if (response.data.url) {
          window.open(response.data.url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="tab-pane fade active show" role="tab-panel">
      <div className="d-flex flex-column gap-7 gap-lg-10 ">
        <div className="card card-flush py-4 flex-row-fluid  w-75 mx-auto ">
          <div className="card-body  ">
            <div className="container">
              <>
                {!_.isEmpty(quotation_data) && quotation_data.is_paid==0 ? (
                  <div>
                    {" "}
                    <div className="">
                      <h1 className="anchor fw-bold mb-5 ms-3 ">
                        Choose a payment method:
                      </h1>
                      <>
                        <div className="w-75 ">
                          <label className="btn btn-outline btn-outline-dashed btn-active-light-danger d-flex flex-stack text-start p-6 mb-5">
                            <div className="d-flex align-items-center me-2">
                              <div className="form-check form-check-custom form-check-solid  me-6">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="mode_paiement_selected"
                                  defaultValue={"1"}
                                  id={1}
                                  onChange={(e) => {
                                    setPayment_mode(e.target.value);
                                    paymentWithClicToPay();
                                  }}
                                />
                              </div>

                              <div className="flex-grow-1">
                                <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                  With ClicToPay
                                </h2>
                              </div>
                            </div>
                            <SweetAlertWarning
                              show={showErrorAlert}
                              handleConfirm={() => setShowErrorAlert(false)}
                              content={message}
                            />
                            <SweetAlertsuccess
                              show={showSucessAlert}
                              handleConfirm={() => {
                                setShowSucessAlert(false);
                                setAddModalShow(false);
                                fetchQuotation()

                              }}
                              content={message}
                            />
                          </label>
                          <label className="btn btn-outline btn-outline-dashed btn-active-light-danger d-flex flex-stack text-start p-6 mb-5 ">
                            <div className="d-flex align-items-center me-2">
                              <div className="form-check form-check-custom form-check-solid  me-6">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="mode_paiement_selected"
                                  defaultValue={"2"}
                                  id={2}
                                  onChange={(e) => {
                                    setPayment_mode(e.target.value);
                                    setAddModalShow(true);
                                  }}
                                />
                              </div>
                              <div className="flex-grow-1">
                                <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                  Stripe
                                </h2>
                              </div>
                            </div>
                          </label>
                          {addModalShow && (
                            <PaymentWithStripe
                              show={addModalShow}
                              onHide={() => setAddModalShow(false)}
                              onSubmit={paymentWithStripe}
                              quotation_data={quotation_data}
                            />
                          )}
                        </div>
                      </>

                      {/* <div className="">
                  <div className="rounded border p-10 d-flex justify-content-center">
                    <div>
                      <div className="d-flex ">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            type="radio"
                            className="form-check-input me-3"
                            name="mode_paiement_selected"
                            defaultValue={"1"}
                            id={1}
                            onChange={(e) => setPayment_mode(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor={1}>
                            <div className="fw-bolder text-gray-800">
                              With ClicToPay
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="separator  my-5" />
                      <div className="d-flex ">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            type="radio"
                            className="form-check-input me-3"
                            name="mode_paiement_selected"
                            defaultValue={"2"}
                            id={2}
                            onChange={(e) => setPayment_mode(e.target.value)}
                          />
                          <label className="form-check-label" htmlFor={2}>
                            <div className="fw-bolder text-gray-800">
                              with an other mode
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                    </div>
                  </div>
                ) : (
                  <div className="justify-content-center">
                    <h1 className="text-center">Le paiement est effectué</h1>
                  </div>
                )}
              </>
            </div>
          </div>
          <div className="card-footer">
            {/* <div className="d-flex justify-content-center ">
              {Payment_mode == 1 && (
                <button
                  type="submit"
                  id="fin"
                  className="btn btn-primary"
                  data-kt-indicator={quoteBtnLoading ? "on" : "off"}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  <span className="indicator-label">Validate</span>
                  <span className="indicator-progress">
                    In progress ...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </button>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;

import React from 'react'
import { useNavigate } from 'react-router'
import payment_sucess from '../../../media/Paymentsuccessful.png'
const PaymentSucess = () => {
    const navigate=useNavigate()
  return (
    <div className="d-flex flex-column flex-root">
    <div className="d-flex flex-column flex-center flex-column-fluid p-10">
      <img
        src={payment_sucess}
        alt=""
        className="mw-100 mb-10 h-lg-450px"
      />
    
      <a
        onClick={() => navigate("/")}
        className="btn btn-primary"
      >
        Go to home
      </a>
    </div>
  </div>
  )
}

export default PaymentSucess
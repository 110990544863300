import microCustomers from "../../api/microCustomers"
import { FETCH_CUSTOMER, FETCH_CUSTOMER_FAILED, FETCH_CUSTOMER_SUCCESS } from "../constants/customer.constant"

export const fetchCustomerById = (OrganizationId, CustomerId, CreatedAt) => async (dispatch) => {
    dispatch({ type: FETCH_CUSTOMER })
    try {
        const response = await microCustomers.get(`/customers/${OrganizationId}/${CustomerId}/${CreatedAt}`)
        dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response.data })
    } catch (error) {
        console.log(error)
        dispatch({ type: FETCH_CUSTOMER_FAILED })
    }
}
import React from "react";
import { useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import LoadingPage from "./components/LoadingProducts";
import { useNavigate } from "react-router-dom";

const RecentProducts = () => {
  const products = useSelector((state) => state.productsReducer.products);
  const navigate=useNavigate()
  return (
    <section
      className="d-flex flex-column align-items-center justify-content-around "
      style={{ backgroundColor: "white", padding: "100px 0px" }}
    >
      <div className="text-center mb-15">
        <h1
          style={{
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "16px",
            color: "#09316f",
            marginBottom: "20px",
          }}
        >
          Produits récents
        </h1>
      </div>
      <div className="row container justify-content-center ">
        {products ? (
          products.slice(0,4).map((el, i) => <ProductCard el={el} key={i} />)
        ) : (
          <LoadingPage />
        )}
      </div>
      <div>
        <a
          onClick={() => navigate("/products")}
          className="btn btn-warning mb-5 mt-10"
        >
          Plus des produits
        </a>
        </div>

    </section>
  );
};

export default RecentProducts;

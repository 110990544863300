import { toast } from "react-toastify";

const options = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const alertError = (text) => {
  return toast.error(text, options);
};
export const alertSuccess = (text) => {
  return toast.success(text, options);
};
export const alertWarning = (text) => {
  return toast.warning(text, options);
};

export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0;
};

import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Rate from "../../../../components/Rate";

const AddModal = (props) => {
  const error_Message = "Required !";
  const [addRating, setAddRating] = useState(props.rating);
  return (
    <Formik
      {...props}
      initialValues={{
        comment: "",
        entity_identifier: props.product_id,
      }}
      validationSchema={Yup.object().shape({
        // score: Yup.number()
        //     .required(`${this.error_Message}`)
        //     .min(0, 'Min value 0.')
        //     .max(5, 'Max value 5.'),
        comment: Yup.string().required(`${error_Message}`),
      })}
      onSubmit={(fields) => {
        props.onSubmit({ ...fields, score: addRating });
        props.onHide();
      }}
    >
      {({ touched, values, handleSubmit, handleChange, errors }) => (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Rating</Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-5 d-flex justify-content-center">
                <Rate starIndex={setAddRating} rating={addRating} />
              </div>
              <div className="form-group mb-5">
                <label htmlFor="comment">Comment</label>
                <Field
                  name="comment"
                  as="textarea"
                  className={
                    "form-control" +
                    (errors.comment && touched.comment ? " is-invalid" : "") +
                    (!errors.comment && touched.comment ? " is-valid" : "")
                  }
                />
                <ErrorMessage
                  name="comment"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-sm btn-default-200"
                onClick={props.onHide}
              >
                Cancel
              </Button>
              <Button className="btn btn-sm btn-primary-200" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddModal;

import {
  FETCH_DOMAINS,
  FETCH_DOMAINS_FAILED,
  FETCH_DOMAINS_SUCCESS,
  FETCH_KEY_FAILED,
  FETCH_KEY_SUCCESS,
} from "../constants/domain.constants";

const initialState = {
  loading: false,
  key: "",
  domain: [],
  error: {},
};

const domainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DOMAINS:
      return {
        ...state,
        loading: true,
      };

    case FETCH_DOMAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        domain: payload,
      };
    case FETCH_KEY_SUCCESS:
      return {
        ...state,
        key: payload,
      };
    case FETCH_KEY_FAILED:
      return {
        ...state,
        key: payload,
        loading: false,
        error: payload,
      };

    case FETCH_DOMAINS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
export default domainReducer;

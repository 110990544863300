
const initialState = {
    msg: ""
}


const alertReducer = (state = initialState, { type, payload }) => {

    switch (type) {

        case "DONE":
            return {
                msg: payload
            }

        default: return state
    }

}

export default alertReducer
import React, { Component } from 'react';
import empty from "../assets/res/images/empty.png";

class Empty extends Component {
    render() {
        const minHeight = this.props.minHeight ? this.props.minHeight : "";
        const img_width = this.props.img_width ? this.props.img_width : "";
        const img_height = this.props.img_height ? this.props.img_height : "";
        return (
            <div className="d-flex flex-column flex-center" style={{ minHeight: `${minHeight}` }}>
                <img src={empty} alt="empty" style={{ height: `${img_height}`, width: `${img_width}` }} />
                {
                    this.props.title &&
                    <div style={{ padding: "15px" }}>
                        <h6 className="fs-4 text-muted">{this.props.title}</h6>
                    </div>
                }
                {
                    this.props.description &&
                    <div style={{ padding: "15px" }} >
                        <h6 className="text-muted">{this.props.description}</h6>
                    </div>
                }
                {this.props.children}
            </div>
        );
    }
}

export default Empty;
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../../../../redux/actions/cart.actions";

const General = ({ product }) => {
  const [qte, setQte] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="card card-flush py-4">
        <div className="card-body">
          <h1>{product.ProductName}</h1>
          <h4 className="text-muted">{product.ProductPrice}</h4>
          <div className="">
            <div className="rating">
              <div className="rating-label checked">
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
              <div className="rating-label checked">
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
              <div className="rating-label checked">
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
              <div className="rating-label checked">
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
              <div className="rating-label">
                <span className="svg-icon svg-icon-2">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="mt-lg-5 d-flex align-items-center">
            <h3 >Price : <span className="text-muted">{product.price}</span> </h3>
          </div>
          <div className="mt-lg-5">
            <h3>Overview : </h3>
            <h5 className="text-muted">{product.name}</h5>
            <ul className="text-muted">
              <li className="mb-0">Digital Marketing Solutions for Tomorrow</li>
              <li className="mb-0">
                Our Talented &amp; Experienced Marketing Agency
              </li>
              <li className="mb-0">Create your own skin to match your brand</li>
            </ul>
          </div>
          <div className="d-flex justify-content-between w-500px mt-lg-5">
            <div className=" w-200px">
              <label className="form-label required">Your Size:</label>
              <select className="form-select mb-2 select2-hidden-accessible">
                <option data-select2-id="select2-data-133-o6hs"></option>
                <option value="S" data-select2-id="select2-data-134-z9l6">
                  XS
                </option>
                <option value="S" data-select2-id="select2-data-135-o1gp">
                  S
                </option>
                <option value="M" data-select2-id="select2-data-136-on35">
                  M
                </option>
                <option value="XL" data-select2-id="select2-data-137-ql4j">
                  XL
                </option>
                <option value="XXL" data-select2-id="select2-data-138-mc9f">
                  XXL
                </option>
                <option value="XXXL" data-select2-id="select2-data-139-x4i6">
                  XXXL
                </option>
              </select>
            </div>
            <div className=" w-200px">
              <label className="required form-label">Quantity</label>
              <input
                type="number"
                name="shelf"
                className="form-control mb-2"
                min={1}
                placeholder="On shelf"
                onChange={() => setQte(qte + 1)}
                value={qte}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between w-350px mt-lg-10">
            {/* <a
              onClick={() => navigate("/products")}
              className="btn btn-sm  btn-light-warning fs-4 fw-bold w-150px text-center"
            >
              Shop now
            </a> */}
            <a
              className="btn btn-sm btn btn-warning w-150px  fs-4 fs-bold text-center"
              onClick={() => dispatch(addToCart({ product, qte }))}
            >
              Add to cart
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;

import React from "react";
import ContentLoader from "react-content-loader";

const LoadingPage = () => {
  return (
    <div>
      <div className=" row mt-lg-10">
        <div className="col-5  gap-7 gap-lg-10 w-100 w-lg-550px mb-7 me-lg-10 ">
          <div className="card card-flush py-4 h-500px">
            <ContentLoader
              height={"100%"}
              speed={2}
              backgroundColor={"#f2f2f2"}
              foregroundColor={"#bcbec2"}
              viewBox="0 0 400 400"
            >
              {/* Only SVG shapes */}

              <rect x="0" y="5" rx="4" ry="4" width="100%" height="300" />
            </ContentLoader>
          </div>
        </div>
        <div className="col-5 py-4 gap-7 gap-lg-10">
          <ContentLoader
            speed={2}
            width={400}
            height={400}
            viewBox="0 0 400 400"
            backgroundColor="#f2f2f2"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="10" rx="3" ry="3" width="67" height="30" />
            <rect x="80" y="10" rx="3" ry="3" width="67" height="30" />
            <rect x="160" y="10" rx="3" ry="3" width="67" height="30" />
            <rect x="0" y="60" rx="3" ry="3" width="100%" height="30" />
            <rect x="0" y="110" rx="3" ry="3" width="100%" height="30" />
            <rect x="0" y="160" rx="3" ry="3" width="100%" height="30" />
            <rect x="0" y="210" rx="3" ry="3" width="100%" height="30" />
            <rect x="0" y="260" rx="3" ry="3" width="100%" height="30" />
            <rect x="0" y="310" rx="3" ry="3" width="100%" height="30" />
          </ContentLoader>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;

import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import api from "../../api";
import EditModal from "./components/editModal";
import languageContext from "../../helpers/Context/lang-context";
import { getStoreData } from "../../helpers/cookie";
const Index = (props) => {
  const [editModalShow, setEditModalShow] = useState(false);
  const lang = useContext(languageContext);
  const organization_id = getStoreData().organization_id;

  function updateText(fields) {
    api
      .post(`/api/i18ns/content/${lang.languageId}`, { ...fields })
      .then((response) => {
        getContent();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [text, setText] = useState("");
  function getContent() {
    api
      .get(
        `/api/i18ns/content/${lang.languageId}?reference=${props.reference}&organization_id=${organization_id}`
      )
      .then((response) => {
        setText(response.data?.text);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getContent();
  }, [lang]);
  return (
    <>
      {text ? text : props.text}
      <Button
        bsPrefix="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
        variant="outline-primary"
        onClick={() => setEditModalShow(true)}
        size="sm"
      >
        <BsPencilSquare variant="primary" size="16px" />
      </Button>
      {editModalShow && (
        <EditModal
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          onSubmit={updateText}
          reference={props.reference}
          languageId={lang.languageId}
          text={text}
        />
      )}
    </>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { FaList } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MediaQuery from "react-responsive";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Nav } from "react-bootstrap";
import CustomSubMenu from "./components/CustomSubMenu";
import { BsLayersFill } from "react-icons/bs";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const location_pathname = location.pathname;
  const [showMenu, setShowMenu] = useState(false);

  const renderVisibleTab = (location_pathname) => {
    switch (location_pathname) {
      case `/profile`:
        return 2;
      case `/profile/dash`:
        return 3;
      case `/profile/quotations`:
        return 4;

      default:
        return 1;
    }
  };
  const [visibleTab, setVisibleTab] = useState(
    renderVisibleTab(location_pathname)
  );
  const customNavLink = (link) => {
    setShowMenu(false);
    navigate(link);
  };
  const data = [
    {
      id: "1",
      tabTitle: "Accueil",
      tabTitle2: "Sommaire",
      tabPath: `/`,
    },
    {
      id: "2",
      tabTitle: "Profile",
      tabTitle2: "Sommaire",
      tabPath: `/profile`,
    },
    {
      id: "3",
      tabTitle: "Tableau de bord",
      tabTitle2: "Sommaire & Rapports",
      tabPath: `/profile/dash`,
    },

    {
      id: "4",
      tabTitle: "Devis",
      tabTitle2: "Voir & Créer",
      tabPath: `/profile/quotations`,
    },

    {
      id: "5",
      tabTitle: "Contrats",
      tabTitle2: "Voir & Créer",
      tabPath: `/profile/contracts`,
    },
  ];

  const listTabs = () => {
    return data.map((item, i) => {
      return (
        <div
          key={i}
          className={
            visibleTab == item.id
              ? "menu-item here show menu-lg-down-accordion me-lg-1"
              : "menu-item menu-lg-down-accordion me-lg-1"
          }
        >
          <span className="menu-link py-3">
            <span
              className="menu-title"
              onClick={() => {
                setVisibleTab(item.id);
                navigate(item.tabPath);
              }}
            >
              <span className="menu-text">{item.tabTitle}</span>
              <span className="menu-desc">{item.tabTitle2}</span>
            </span>
          </span>
        </div>
      );
    });
  };

  return (
    <>
      <MediaQuery maxWidth={991}>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-sm btn-icon btn-secondary m-5 "
            onClick={() => setShowMenu(true)}
          >
            <FaList />
          </button>

          <Offcanvas
            show={showMenu}
            scroll="false"
            style={{ left: "0px", width: "270px" }}
            onHide={() => setShowMenu(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="menu menu-column menu-active-bg menu-state-primary menu-title-gray-700 fs-6 menu-rounded w-100 fw-bold ">
                <Nav className="flex-column menu-nav" bsPrefix="">
                  {data.map((item) => {
                    return (
                      <CustomSubMenu
                        icon_nav={BsLayersFill}
                        nav_name={item.tabTitle}
                        link={item.tabPath}
                        customNavLink={customNavLink}
                        key={item.id}
                      />
                    );
                  })}
                </Nav>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={992}>
        <div
          className="app-header-secondary app-header-mobile-drawer border-bottom "
          style={{ animationDuration: "0.3s" }}
        >
          <div className=" container-xxl app-container-fit-mobile d-flex align-items-stretch">
            <div className="header-menu d-flex align-items-stretch w-100">
              <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-state-primary menu-title-gray-700 menu-arrow-gray-400 menu-bullet-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                {listTabs()}
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default Navigation;

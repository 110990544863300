import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerInfos, getUserInfos } from "../../helpers/user.cookie";
import swal from "sweetalert";
import microCustomers from "../../api/microCustomers";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { removeCart } from "../../redux/actions/cart.actions";
import { IoBagCheckOutline } from "react-icons/io5";
import MediaQuery from "react-responsive";
import blankimage from "../../media/blank-image.svg";
import emptyCart from "../../media/empty-cart-1.jpg";
import { renderToast } from "../../helpers/utils/Toast";
import CustomErrorMessage from "../../components/CustomErrorMessage";
import { isObjectEmpty } from "../../helpers/generalFunction";
import { Spinner } from "react-bootstrap";
const ProceedToCheckout = () => {
  const cart = useSelector((state) => state.cartReducer.cart);
  const msg = useSelector((state) => state.alertReducer.msg);
  const user_data = getUserInfos();
  const customer_data = getCustomerInfos();
  const [validationErrors, setValidationErros] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getInfos = () => {
    let QuotationProducts = [];
    let TotalPrice = 0;
    for (let i = 0; i < cart.length; i++) {
      QuotationProducts.push({
        id: cart[i].product.id,
        price: cart[i].product.price,
        number: cart[i].qte,
        name: cart[i].product.name,
        description: cart[i].product.description,
      });
      TotalPrice += cart[i].product.price * cart[i].qte;
    }

    return [QuotationProducts, TotalPrice];
  };

  const createQuot = async (fields) => {
    setIsLoading(true);
    const res = getInfos();
    const newQuotation = {
      customer_id: customer_data.id,
      organization_id: user_data.organization_id,
      cart_list: res[0],
      street: fields.street,
      address_complement: fields.address_complement,
      city: fields.city,
      region: fields.region,
      zipcode: fields.zipcode,
    };
    try {
      await microCustomers
        .post(`/customers/quotations`, newQuotation)
        .then((response) => {
          navigate("/quotations/" + response.data.data.id);

          dispatch(removeCart());
        });
    } catch (error) {
      if (error.response) {
        var message = error.response.data.message;
        if (error.response.status === 422) {
          setValidationErros(error.response.data.errors);
          renderToast(message, "error");
        } else {
          renderToast(message, "error");
        }
      } else {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" flex-column flex-row-fluid mx-5 ">
      <div className=" d-flex flex-row flex-column-fluid">
        <div className="app-main flex-column flex-row-fluid">
          <Formik
            initialValues={{
              is_corporate: customer_data.is_corporate
                ? customer_data.is_corporate
                : "",
              company_name: customer_data.company_name
                ? customer_data.company_name
                : "",
              firstname: customer_data.firstname ? customer_data.firstname : "",
              lastname: customer_data.lastname ? customer_data.lastname : "",
              email: customer_data.email ? customer_data.email : "",
              mobile: customer_data.mobile ? customer_data.mobile : "",
              street: customer_data.address ? customer_data.address.street : "",
              address_complement: customer_data.address
                ? customer_data.address.address_complement
                : "",
              city: customer_data.address ? customer_data.address.city : "",
              region: customer_data.address ? customer_data.address.region : "",
              zipcode: customer_data.address
                ? customer_data.address.zipcode
                : "",
            }}
            validationSchema={Yup.object().shape({
              // company_name: Yup.string().required(
              //   `Raison sociale est obligatoire`
              // ),
              // firstname: Yup.string().required(`Prénom est obligatoire`),
              // lastname: Yup.string().required(`Nom est obligatoire`),
              // email: Yup.string()
              //   .email("Email invalide")
              //   .required(`Nom est obligatoire`),
              // street: Yup.string().required(`Voie est obligatoire`),
              // zipcode: Yup.string().required(`Code postale est obligatoire`),
              // // address_complement: Yup.string().required(
              // //   `Adresse complement est obligatoire`
              // // ),
              // city: Yup.string().required(`Ville  est obligatoire`),
              // region: Yup.string().required(`Région est obligatoire`),
            })}
            onSubmit={(fields) => {
              if (cart.length > 0) createQuot(fields);
            }}
          >
            {({ touched, values, handleSubmit, handleChange, errors }) => (
              <Form>
                <div className="row mt-20 w-100">
                  <div className="col-md-12 col-lg-6">
                    <div className="d-flex flex-column ">
                      <div className="card card-flush shadow-sm rounded-4 py-4 mb-10">
                        <div className="card-body pt-0">
                          <div className="d-flex flex-column gap-5 gap-md-7 mb-10">
                            <div className="fs-3 fw-bold mb-n2">
                              Customer Details
                            </div>
                            {customer_data.is_corporate == 1 ? (
                              <div className="flex-row-fluid">
                                <label
                                  className="form-label"
                                  htmlFor="company_name"
                                >
                                  Nom de l’entreprise
                                </label>
                                <Field
                                  name="company_name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.company_name && touched.company_name
                                      ? " is-invalid"
                                      : "") +
                                    (!errors.company_name &&
                                    touched.company_name
                                      ? " is-valid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="company_name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            ) : (
                              <div className="d-flex flex-column flex-md-row gap-5">
                                <div className="fv-row flex-row-fluid">
                                  <label
                                    className="form-label"
                                    htmlFor="firstname"
                                  >
                                    First name
                                  </label>
                                  <Field
                                    name="firstname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.firstname && touched.firstname
                                        ? " is-invalid"
                                        : "") +
                                      (!errors.firstname && touched.firstname
                                        ? " is-valid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="firstname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="flex-row-fluid">
                                  <label
                                    className="form-label"
                                    htmlFor="lastname"
                                  >
                                    Last name
                                  </label>
                                  <Field
                                    name="lastname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.lastname && touched.lastname
                                        ? " is-invalid"
                                        : "") +
                                      (!errors.lastname && touched.lastname
                                        ? " is-valid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="lastname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="flex-row-fluid">
                              <label className="form-label" htmlFor="email">
                                Email
                              </label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "") +
                                  (!errors.email && touched.email
                                    ? " is-valid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="flex-row-fluid">
                              <label className="form-label" htmlFor="mobile">
                                Phone number
                              </label>
                              <Field
                                name="mobile"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.mobile && touched.mobile
                                    ? " is-invalid"
                                    : "") +
                                  (!errors.mobile && touched.mobile
                                    ? " is-valid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="mobile"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card card-flush shadow-sm rounded-4 py-4 mb-10">
                        <div className="card-body pt-0">
                          <div className="d-flex flex-column gap-5 gap-md-7 mb-10">
                            <div className="fs-3 fw-bold mb-n2">Address </div>
                            <div className="d-flex gap-4 w-100">
                              <div className="d-flex flex-column flex-md-row gap-5 w-50">
                                <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                  <label className="required form-label">
                                    Street
                                  </label>
                                  <Field
                                    name="street"
                                    type="text"
                                    className={
                                      "form-control" +
                                      ((errors.street && touched.street) ||
                                      (!isObjectEmpty(validationErrors) &&
                                        Object.keys(validationErrors).includes(
                                          "street"
                                        ))
                                        ? " is-invalid"
                                        : "") +
                                      (!errors.street && touched.street
                                        ? " is-valid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="street"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  <CustomErrorMessage
                                    validationErrors={validationErrors}
                                    field="street"
                                  />
                                  <div className="fv-plugins-message-container invalid-feedback" />
                                </div>
                              </div>
                              <div className="fv-row flex-row-fluid fv-plugins-icon-container w-50">
                                <label className="required form-label">
                                  Zipcode
                                </label>
                                <Field
                                  name="zipcode"
                                  type="text"
                                  className={
                                    "form-control" +
                                    ((errors.zipcode && touched.zipcode) ||
                                    (!isObjectEmpty(validationErrors) &&
                                      Object.keys(validationErrors).includes(
                                        "zipcode"
                                      ))
                                      ? " is-invalid"
                                      : "") +
                                    (!errors.zipcode && touched.zipcode
                                      ? " is-valid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="zipcode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                <CustomErrorMessage
                                  validationErrors={validationErrors}
                                  field="zipcode"
                                />
                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>

                            <div className="d-flex flex-column flex-md-row gap-5">
                              <div className="flex-row-fluid">
                                <label className="form-label">City</label>
                                <Field
                                  name="city"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.city && touched.city
                                      ? " is-invalid"
                                      : "") +
                                    (!errors.city && touched.city
                                      ? " is-valid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="fv-row flex-row-fluid fv-plugins-icon-container">
                                <label className="required form-label">
                                  Region
                                </label>
                                <Field
                                  name="region"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.region && touched.region
                                      ? " is-invalid"
                                      : "") +
                                    (!errors.region && touched.region
                                      ? " is-valid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="region"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                <div className="fv-plugins-message-container invalid-feedback" />
                              </div>
                            </div>
                            <div className="flex-row-fluid">
                              <label className="form-label">
                                Additional address
                              </label>
                              <Field
                                name="address_complement"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.address_complement &&
                                  touched.address_complement
                                    ? " is-invalid"
                                    : "") +
                                  (!errors.address_complement &&
                                  touched.address_complement
                                    ? " is-valid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="address_complement"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-md-12 col-lg-6">
                    <div className="d-flex flex-column ">
                      <div className="card card-flush shadow-sm rounded-4 py-4 mb-10">
                        <div className="card-body pt-0">
                          <div className="d-flex flex-column gap-10">
                            <MediaQuery maxWidth={698}>
                              <div className="card">
                                <div className="card-body pt-5">
                                  {cart.length !== 0 ? (
                                    <>
                                      {cart.map((el, i) => (
                                        <div className="d-flex align-items-sm-center mt-5 shadow-sm rounded-2 p-5">
                                          <div className="symbol symbol-70px me-5 ">
                                            <span className="symbol-label">
                                              <img
                                                src={
                                                  el.product.default_image
                                                    ? el.product.default_image
                                                    : blankimage
                                                }
                                                className="h-75 w-75 align-self-center"
                                                alt=""
                                              />
                                            </span>
                                          </div>
                                          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                            <div className="flex-grow-1 me-2">
                                              <span className="text-gray-800 text-hover-primary fs-2 fw-bold">
                                                {el.product.name}
                                              </span>
                                              <div className="fw-semibold fs-3">
                                                Price : $
                                                <span data-kt-ecommerce-edit-order-filter="price">
                                                  {el.product.price}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="flex-grow-1 me-2">
                                              <div className="fw-semibold fs-3">
                                                Qantity :
                                                <span className="fw-bold ms-3">
                                                  {el.qte}
                                                </span>
                                              </div>
                                              <div className="fw-semibold fs-3">
                                                Total :
                                                <span className="fw-bold ms-3">
                                                  {el.product.price * el.qte}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="separator-dashed separator mt-10" />
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <div className="d-flex"></div>
                                  )}
                                  <div className="separator mt-5" />
                                  <div className="d-flex container text-center justify-content-end">
                                    <div className=" mx-5">
                                      <div className="fs-1 fw-bold ">
                                        Total :
                                      </div>
                                    </div>
                                    <div className="fs-1  text-end ">
                                      {cart.length > 0
                                        ? cart.reduce(
                                            (total, item) =>
                                              total +
                                              item.product.price * item.qte,
                                            0
                                          )
                                        : 0}
                                      $
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between text-center py-5">
                                    <button
                                      className="btn btn-sm btn-success   fs-4 fs-bold text-center "
                                      onClick={() => navigate("/cart")}
                                    >
                                      <span>Cancel</span>
                                    </button>
                                    <button
                                      type="submit"
                                      className=" btn btn-sm  btn-primary    fs-5 fs-bold "
                                    >
                                      {isLoading ? (
                                        <>
                                          <Spinner /> Proccessing
                                        </>
                                      ) : (
                                        <span className="svg-icon svg-icon-1 ">
                                          <IoBagCheckOutline />
                                          <span> Place Order</span>
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </MediaQuery>
                            <MediaQuery minWidth={698}>
                              <div>
                                <div className="table-responsive h-300px mt-8">
                                  <div
                                    className="dataTables_scrollBody "
                                    style={{
                                      position: "relative",
                                      overflow: "auto",
                                      maxHeight: 400,
                                      width: "100%",
                                    }}
                                  >
                                    {cart.length > 0 ? (
                                      <table
                                        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                        style={{ width: "100%" }}
                                      >
                                        <thead>
                                          <tr
                                            className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
                                            style={{ height: 50 }}
                                          >
                                            <th
                                              className="min-w-200px sorting"
                                              aria-controls="kt_ecommerce_edit_order_product_table"
                                              rowSpan={1}
                                              colSpan={1}
                                              style={{
                                                width: "510.312px",
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                borderTopWidth: 0,
                                                borderBottomWidth: 0,
                                              }}
                                            >
                                              <div
                                                className="dataTables_sizing"
                                                style={{
                                                  overflow: "hidden",
                                                }}
                                              >
                                                Product
                                              </div>
                                            </th>
                                            <th
                                              className="min-w-100px text-end pe-5 sorting"
                                              aria-controls="kt_ecommerce_edit_order_product_table"
                                              rowSpan={1}
                                              colSpan={1}
                                              style={{
                                                width: "286.453px",
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                borderTopWidth: 0,
                                                borderBottomWidth: 0,
                                              }}
                                            >
                                              <div
                                                className="dataTables_sizing"
                                                style={{
                                                  overflow: "hidden",
                                                }}
                                              >
                                                Qty
                                              </div>
                                            </th>
                                            <th
                                              className="min-w-100px text-end pe-5 sorting"
                                              aria-controls="kt_ecommerce_edit_order_product_table"
                                              rowSpan={1}
                                              colSpan={1}
                                              style={{
                                                width: "286.453px",
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                borderTopWidth: 0,
                                                borderBottomWidth: 0,
                                              }}
                                            >
                                              <div
                                                className="dataTables_sizing"
                                                style={{
                                                  overflow: "hidden",
                                                }}
                                              >
                                                Total
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody className="fw-semibold text-gray-600 w-100">
                                          {cart.map((el, i) => (
                                            <tr className="odd" key={i}>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <div className="symbol symbol-50px">
                                                    <img
                                                      src={
                                                        el.product.default_image
                                                          ? el.product
                                                              .default_image
                                                          : blankimage
                                                      }
                                                      className="symbol-label"
                                                      width={"100%"}
                                                      height={"100%"}
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="ms-5">
                                                    <span className="text-gray-800 text-hover-primary fs-5 fw-bold">
                                                      {el.product.name}
                                                    </span>
                                                    <div className="fw-semibold fs-7">
                                                      Price: $
                                                      <span data-kt-ecommerce-edit-order-filter="price">
                                                        {el.product.price}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-end pe-5">
                                                <span className="fw-bold ms-3">
                                                  {el.qte}
                                                </span>
                                              </td>
                                              <td className="text-end pe-5">
                                                <span className="fw-bold ms-3">
                                                  {el.product.price * el.qte}
                                                </span>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                        <img
                                          src={emptyCart}
                                          title=""
                                          alt=""
                                          width={"200px"}
                                          height={"200px"}
                                        />
                                        <span>Aucune produit choisi</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="separator" />
                                <div className="d-flex container text-center justify-content-end">
                                  <div className=" mx-5">
                                    <div className="fs-1 text-dark ">
                                      Total :
                                    </div>
                                  </div>
                                  <div className="fs-1 text-dark text-end ">
                                    {cart.length > 0
                                      ? cart.reduce(
                                          (total, item) =>
                                            total +
                                            item.product.price * item.qte,
                                          0
                                        )
                                      : 0}
                                    $
                                  </div>
                                </div>

                                <div className="d-flex justify-content-between py-5">
                                  <button
                                    className="btn btn-sm btn-success   fs-4 fs-bold text-center "
                                    onClick={() => navigate("/cart")}
                                  >
                                    <span>Cancel</span>
                                  </button>
                                  <button
                                    type="submit"
                                    className=" btn btn-sm  btn-primary    fs-4 fs-bold text-center"
                                    disabled={cart.length <= 0 || isLoading}
                                  >
                                    {isLoading ? (
                                      <div className="d-flex gap-4 align-items-center">
                                        <Spinner size="sm" />
                                        <span>Processing</span>
                                      </div>
                                    ) : (
                                      <span className="svg-icon svg-icon-1 ">
                                        <IoBagCheckOutline />
                                        <span> Place Order</span>
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </MediaQuery>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProceedToCheckout;

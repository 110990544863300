import React from 'react'
import {  useNavigate } from 'react-router-dom'

const EmptyCard = ({image,title,action,location}) => {
    const navigate=useNavigate()
  return (
    <div className="card">
    <div className="card-body">
      <div className="card-px text-center pt-15 pb-15">
        <h2 className="fs-2x fw-bold mb-0">{title}</h2>
       
       
      <div className="text-center pb-15 px-5">
        <img
          src={image}
          alt=""
          className="mw-100 h-200px h-sm-325px"
        />
      </div>
        <button
          onClick={()=>navigate(location)}
          className="btn btn-primary er fs-6 px-8 py-4"
         
        >
          {action}
        </button>
      </div>
    </div>
  </div>
  
  )
}

export default EmptyCard
import React from "react";
import { useState } from "react";

const Pagination = ({
  totalPages,
  setCurrentPage,
  setPerPage,
  currentPage,
  perPage,
  delta,
}) => {
  const [activeElement, setActiveElement] = useState(1);

  const pagination = (c, m, d) => {
    let current = parseInt(c),
      last = parseInt(m),
      delta = parseInt(d),
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    return rangeWithDots;
  };

  const pages = () => {
    return pagination(currentPage, totalPages, delta).map((el,i) => (
      <li key={i}
        className={
          activeElement == el
            ? "paginate_button page-item active"
            : "paginate_button page-item"
        }
        onClick={() =>
          el != "..." ? (setCurrentPage(el), setActiveElement(el)) : null
        }
      >
        <a href="#" className="page-link">
          {" "}
          {el}
        </a>
      </li>
    ));
  };

  return (
    <div className="main">
      <div className="d-flex align-items-center justify-content-between">
        <div className="">
          {perPage && (
            <div className="dataTables_length">
              <label>
                <select 
                  className="form-select form-select-sm form-select-solid"
                  onClick={(e) => setPerPage(e.target.value)}
                >
                  <option value={1} 
                  // selected={perPage == 1 ? "selected" : ""}
                  >
                    1
                  </option>
                  <option value={10} 
                  // selected={perPage == 10 ? "selected" : ""}
                  >
                    10
                  </option>
                  <option value={25} 
                  // selected={perPage == 25 ? "selected" : ""}
                  >
                    25
                  </option>
                  <option value={50} 
                  // selected={perPage == 50 ? "selected" : ""}
                  >
                    50
                  </option>
                  <option
                    value={100}
                    // selected={perPage == 100 ? "selected" : ""}
                  >
                    100
                  </option>
                </select>
              </label>
            </div>
          )}
        </div>
        <div className="">
          <div className="dataTables_paginate paging_simple_numbers">
            <ul className="pagination">
              <li
                className={
                  currentPage == 1
                    ? "paginate_button page-item previous disabled"
                    : "paginate_button page-item previous"
                }
                onClick={() => (
                  setCurrentPage(
                    currentPage > totalPages ? currentPage - 1 : currentPage
                  ),
                  setActiveElement(
                    currentPage > totalPages ? currentPage - 1 : currentPage
                  )
                )}
              >
                <a href="#" className="page-link">
                  <i className="previous" />
                </a>
              </li>
              {pages()}
              <li
                className={
                  currentPage == totalPages
                    ? "paginate_button page-item next disabled"
                    : "paginate_button page-item next"
                }
                onClick={() => (
                  setCurrentPage(
                    currentPage < totalPages ? currentPage + 1 : currentPage
                  ),
                  setActiveElement(
                    currentPage < totalPages ? currentPage + 1 : currentPage
                  )
                )}
              >
                <a href="#" className="page-link">
                  <i className="next" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

import axios from "axios";
import { getaccesskey } from "../helpers/cookie";
import { getToken, removeUserInfos } from "../helpers/user.cookie";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
// let baseURL = process.env.REACT_APP_MICRO_STORES_API_URL;
let baseURL = process.env.REACT_APP_API_URL;
let api_access_key = process.env.REACT_APP_API_ACCESS_KEY;

let microStores = axios.create({
  baseURL: baseURL + "/api/",
});

microStores.interceptors.request.use(
  function (config) {
    let access_key = getaccesskey();
    const token = getToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      config.headers["Authorization"] = "";
    }
    if (access_key) {
      config.headers["X-Api-Key"] = access_key;
    } 

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
microStores.interceptors.response.use(
  async function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status == 401) {
      removeUserInfos();
      history.push("/401");
      history.go("/401");
    }
    if (error.response && error.response.status == 404) {
      history.push("/404");
      history.go("/404");
    }
    // if (error.response && error.response.status >= 500) {
    //   history.push("/501");
    //   history.go("/501");
    // }
    return Promise.reject(error);
  }
);

export default microStores;

import React from 'react'

const ErrorServer = () => {
  return (
    <div
    className=" d-flex flex-row-fluid bgi-size-cover bgi-position-center"
  
  >
    <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
      <h1 className="fs-2x fw-boldest text-info mt-10 mt-md-0 mb-12">
        Oops!
      </h1>
      <p className="fw-boldest display-4">Something went wrong here.</p>
      <p className="fs-3">
        We're working on it and we'll get it fixedas soon possible.You can back or
        use our Help Center.
      </p>
    </div>
  </div>
  
  )
}

export default ErrorServer

export const FETCH_QUOTATIONS = "FETCH_QUOTATIONS"
export const FETCH_QUOTATIONS_SUCCESS = "FETCH_QUOTATIONS_SUCCESS"
export const FETCH_QUOTATIONS_FAILED = "FETCH_QUOTATIONS_FAILED"

export const FETCH_QUOTATION_BY_ID = "FETCH_QUOTATION_BY_ID"
export const FETCH_QUOTATION_BY_ID_SUCCESS = "FETCH_QUOTATION_BY_ID_SUCCESS"
export const FETCH_QUOTATION_BY_ID_FAILED = "FETCH_QUOTATION_BY_ID_FAILED"

export const ADD_QUOTATION = "ADD_QUOTATION"
export const ADD_QUOTATION_SUCCESS = "ADD_QUOTATION_SUCCESS"
export const ADD_QUOTATION_FAILED = "ADD_QUOTATION_FAILED"
import microStores from "../../api/microStores";
import { setaccesskey, setStoreData, setStoreId } from "../../helpers/cookie";
import {
  FETCH_DOMAINS,
  FETCH_DOMAINS_FAILED,
  FETCH_DOMAINS_SUCCESS,
  FETCH_KEY_FAILED,
  FETCH_KEY_SUCCESS,
} from "../constants/domain.constants";
import { createBrowserHistory } from "history";
import _ from "lodash";
import { fetchProducts } from "./products.actions";
const history = createBrowserHistory();
function changeIcon(icon, newTitle) {
  let title_el = document.querySelector("title");
  if (title_el) title_el.innerHTML = newTitle;
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = `${icon}`;
}
export const fetchDomains = (domainName) => async (dispatch) => {
  dispatch({ type: FETCH_DOMAINS });
  try {
    const domain = await microStores.get(
      `/stores/domains?search=${domainName}`
    );
    const organization = domain.data.data[0];
    try {
      const accesskey = await microStores.get(
        `/get-access-key?organization_id=${organization.organization_id}`
      );
      if (_.isEmpty(accesskey.data.data)) {
        setaccesskey("");
        dispatch({ type: FETCH_KEY_FAILED, payload:"no acess key" });
      } else {
        setaccesskey(accesskey.data.data.key);
        
        dispatch(fetchProducts(organization.store_id));
        setStoreId(organization.store_id);
        const icon = organization.store.default_image;
        const newTitle = organization.store.name;
        changeIcon(icon, newTitle);
        setStoreData(organization);
        dispatch({ type: FETCH_DOMAINS_SUCCESS, payload: domain.data.data });
        dispatch({ type:FETCH_KEY_SUCCESS,payload:accesskey.data.data.key});
      }
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    dispatch({ type: FETCH_DOMAINS_FAILED, payload: error });
    console.log(error);
  }
};
export const fetchkey = (organization_id) => async () => {
  try {
    const accesskey = await microStores.get(
      `/get-access-key?organization_id=${organization_id}`
    );
    if (_.isEmpty(accesskey.data.data)) {
      history.push("/error-key");
      // history.go("/error-key");
    } else {
      setaccesskey(accesskey.data.data.key);
    }
  } catch (error) {
    console.log(error);
  }
};

import React, { useEffect, useState } from "react";
import _ from "lodash";

import AddGethModal from "./components/addModal";
import { Button, Spinner } from "react-bootstrap";
import api from "../../../api";
import { getUserInfos } from "../../../helpers/user.cookie";
import { FaEthereum } from "react-icons/fa";

const Index = () => {
  const [addModalShow, setAddModalShow] = useState(false);
  const [balance, setBalance] = useState("");
  const [balance_xbc, setBalance_xbc] = useState("");
  const user_data = getUserInfos();
  const [geth_account, setGeth_account] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (fields) => {
    createGeth(fields);
  };
  const createGeth = (fields) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api
      .post("api/application/geth/create-account", { ...fields }, options)
      .then((response) => {
        fetchUserGethAccount();
      })
      .catch((error) => console.log(error));
  };

  function fetchUserGethAccount() {
    setIsLoading(true);
    const user_id = user_data.id;
    const entity_id = user_data.entity_id;

    api
      .get(
        "api/application/geth/get-account?entity_id=4&entity_identifier=" +
          user_id
      )
      .then((response) => {
        setIsLoading(false);
        setGeth_account(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function fetchUserGethAccountBalance() {
    const entity_id = user_data.entity_id;
    const user_id = user_data.id;
    api
      .get(
        "api/application/geth/account/balance?entity_id=4&entity_identifier=" +
          user_id
      )
      .then((response) => {
        setBalance(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchUserGethAccountBalanceXbc() {
    const entity_id = user_data.entity_id;
    const user_id = user_data.id;

    api
      .get(
        "api/application/geth/account/balance?token_id=2&entity_id=4&entity_identifier=" +
          user_id
      )
      .then((response) => {
        setBalance_xbc(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchUserGethAccount();
    fetchUserGethAccountBalance();
    fetchUserGethAccountBalanceXbc();
  }, []);
  const userInfos = user_data ? user_data : {};
  return (
    <>
      <div
        id="kt_content_container"
        className="d-flex flex-column-fluid align-items-start container "
      >
        <div className=" flex-row-fluid" id="kt_content">
          <div className="mb-5 mt-10">
            <div className="alert alert-success d-flex justify-content-between   cursor-pointer mb-0">
              <div className="d-flex">
                <div className="me-2">
                  <FaEthereum size="25px" className="mb-2" />
                </div>
                <div className="d-flex flex-column ">
                  <h4 className="mb-1 text-primary">
                    {geth_account && geth_account.length != 0 ? null : (
                      <span className="bullet bullet-dot bg-danger h-6px w-6px translate-middle top-0 start-50 animation-blink"></span>
                    )}
                    Your journey starts here.
                  </h4>
                  <span>
                    Your blockchain account is the ID you use to access all
                    smart place services like Rating, Voting, and more.
                    <br></br>
                    It includes at the same time your Financial and operational
                    activities. It is like a Digital <br />
                    Wallet where you store your Smart Digital Cards in order to
                    facilitate your interaction with our partners.
                    <br></br>
                    <i
                      className="bi bi-arrow-right"
                      style={{ color: "red" }}
                    ></i>{" "}
                    Please follow the blinking red dot to get started using
                    Smart Place and investing.
                  </span>
                </div>
              </div>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center w-200px">
                  <Spinner animation="border" role="status" variant="danger">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : !_.isEmpty(geth_account) ? (
                <div className=" card card-body d-flex  ms-20">
                  <div className="d-flex flex-column flex-wrap ">
                    <div className=" mb-3">
                      <div className="fs-6 text-gray-800 fw-bolder">
                        {geth_account.address}
                      </div>
                      <div className="fw-bold text-gray-400">
                        Blockchain account
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="border border-2   rounded min-w-125px  h-50px mx-10 me-7 px-2 mb-3">
                        <div className="fs-6 text-gray-800 fw-bolder">
                          {balance == "" ? "0" : balance}
                        </div>
                        <div className="fw-bold text-danger">
                          <span className="badge badge-success me-2">DMG</span>
                        </div>
                      </div>
                      <div className="border  border-2   rounded min-w-125px h-50px  me-7 mx-10 px-2 mb-3">
                        <div className="fs-6 text-gray-800 fw-bolder">
                          {balance_xbc == "" ? "0" : balance_xbc}
                        </div>
                        <div className="fw-bold text-danger">
                          <span className="badge badge-danger me-2">XBC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center">
                  <button
                    className="btn btn-danger btn-sm  cursor-pointer"
                    onClick={() => setAddModalShow(true)}
                  >
                    Get now your Blockchain Account
                    {geth_account && geth_account.length != 0 ? (
                      <span className="badge badge-circle badge-white ms-2">
                        {" "}
                        1{" "}
                      </span>
                    ) : (
                      <span className="badge badge-circle badge-white ms-2 pulse pulse-danger ">
                        <span className="position-relative">1</span>
                        <span className="pulse-ring"></span>
                      </span>
                    )}
                  </button>
                </div>
              )}
            </div>
            {addModalShow && (
              <AddGethModal
                show={addModalShow}
                onHide={() => setAddModalShow(false)}
                onSubmit={(e) => onSubmit(e)}
                organization_id={user_data.organization_id}
                entity_id={4}
                entity_identifier={user_data.id}
              />
            )}
          </div>
        </div>

        {/* <div className="content flex-row-fluid" id="kt_content">
        <div className="card mb-5 mb-xxl-8 shadow-sm rounded-4 mt-20">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap">
              <div className="me-7 mb-4">
                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  <img src={LogoImage} />
                  <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <a className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">
                        {userInfos.name}
                      </a>
                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                            fill="#00A3FF"
                          ></path>
                          <path
                            className="permanent"
                            d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                            fill="white"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                      <span className="text-gray-500 text-hover-primary fs-5 fw-bolder me-1">
                        {userInfos.email}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-stack">
                    {!_.isEmpty(geth_account) ? (
                      <div className="d-flex flex-wrap">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                          <div className="fs-6 text-gray-800 fw-bolder">
                            {geth_account.address}
                          </div>
                          <div className="fw-bold text-gray-400">
                            Blockchain account
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                          <div className="fs-6 text-gray-800 fw-bolder">
                            {balance}
                          </div>
                          <div className="fw-bold text-danger">
                            <span className="badge badge-success me-2">
                              DMG
                            </span>
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                          <div className="fs-6 text-gray-800 fw-bolder">
                            {balance_xbc}
                          </div>
                          <div className="fw-bold text-danger">
                            <span className="badge badge-danger me-2">XBC</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => setAddModalShow(true)}
                      >
                        Get Blockchain Account
                      </Button>
                    )}

                    {addModalShow && (
                      <AddGethModal
                        show={addModalShow}
                        onHide={() => setAddModalShow(false)}
                        onSubmit={onSubmit}
                        organization_id={userInfos.organization_id}
                        entity_id={user_data.entity_id}
                        entity_identifier={userInfos.id}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div> */}
      </div>
      <div className="container">
        <div className="card mt-10  shadow-sm  ">
          <div className="card-header ">
            <div className="card-title m-0">
              <h3 className="fw-bold m-0"> Details Profile </h3>
            </div>
            {/* <button className="btn btn-sm btn-primary align-self-center">
              Modifer le profile
            </button> */}
          </div>
          <div className="card-body p-9">
            <div className="row mb-7">
              <label className="col-lg-4 fw-bolder fs-6 text-muted">
                Nom complet :
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-5 text-gray-800">
                  {user_data.name}
                </span>
              </div>
            </div>
            <div className="row mb-7">
              <label className="col-lg-4 fw-bolder fs-6 text-muted">
                Email :
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-5 text-gray-800">
                  {user_data.email}
                </span>
              </div>
            </div>

            <div className="row mb-7">
              <label className="col-lg-4 fw-bolder fs-6 text-muted">
                Téléphone de contact :
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-5 text-gray-800"></span>
              </div>
            </div>
            <div className="row mb-7">
              <label className="col-lg-4 fw-bolder fs-6 text-muted">
                Addresse :
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-5 text-gray-800"></span>
              </div>
            </div>
            <div className="row mb-7">
              <label className="col-lg-4 fw-bolder fs-6 text-muted">
                Pays :
              </label>

              <div className="col-lg-8">
                <span className="fw-bolder fs-5 text-gray-800"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { getStoreData } from "../../../helpers/cookie";

const EditModal = ({ show, onHide, onSubmit, languageId, reference,text }) => {
  const error_Message = "Required !";
  const organization_id = getStoreData().organization_id;
 
  function languageCode(id) {
    switch (id) {
      case 1:
        return "EN";

      case 2:
        return "FR";

      case 3:
        return "AR";

      default:
        break;
    }
  }
 
  return (
    <Formik
      initialValues={{
        organization_id: organization_id,
        code: languageCode(languageId),
        reference: reference,
        text: text ? text : "",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        text: Yup.string().required(`${error_Message}`),
      })}
      onSubmit={(fields) => {
        onSubmit(fields);
        onHide();
      }}
    >
      {({ touched, values, handleSubmit, handleChange, errors }) => (
        <Modal
          onHide={onHide}
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit text
              <span className="badge badge-light-success ms-2">
                {reference}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-5">
                <label htmlFor="text">Text</label>
                <Field
                  name="text"
                  as="textarea"
                  className={
                    "form-control" +
                    (errors.text && touched.text ? " is-invalid" : "") +
                    (!errors.text && touched.text ? " is-valid" : "")
                  }
                />
                <ErrorMessage
                  name="text"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-sm btn-default-200" onClick={onHide}>
                Cancel
              </Button>
              <Button className="btn btn-sm btn-primary-200" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default EditModal;

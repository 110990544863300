import { ADD_QUOTATION, ADD_QUOTATION_FAILED, ADD_QUOTATION_SUCCESS, FETCH_QUOTATIONS, FETCH_QUOTATIONS_FAILED, FETCH_QUOTATIONS_SUCCESS, FETCH_QUOTATION_BY_ID, FETCH_QUOTATION_BY_ID_FAILED, FETCH_QUOTATION_BY_ID_SUCCESS } from "../constants/quotations.constants";


const initialState = {
    isLoading: false,
    quotations: [],
    quotation: {},
    error: {},
    msg: ""
}

const quotationsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_QUOTATIONS:
        case FETCH_QUOTATION_BY_ID:
        case ADD_QUOTATION:
            return {
                ...state,
                isLoading: true
            }

        case FETCH_QUOTATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                quotations: payload.quotations
            }

        case FETCH_QUOTATION_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                quotation: payload.quotation
            }
       
        case ADD_QUOTATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                msg: payload.msg
            }

        case FETCH_QUOTATIONS_FAILED:
        case FETCH_QUOTATION_BY_ID_FAILED:
        case ADD_QUOTATION_FAILED:
            return {
                ...state,
                isLoading: false,
                msg: payload.msg,
                error: payload.msg
            }
        default:
            return state;
    }
};
export default quotationsReducer;

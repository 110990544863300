import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class BarChartWithCustomizedEvent extends PureComponent {
    static demoUrl = 'https://codesandbox.io/s/bar-chart-with-customized-event-4k1bd';

    state = {
        data: [
            {
                name: 'Avril 04',
                ventes: 4000,
                pv: 2400,
                amt: 2400,
            },
            {
                name: 'Avril 07',
                ventes: 3000,
                pv: 1398,
                amt: 2210,
            },
            {
                name: 'Avril 10',
                ventes: 2000,
                pv: 9800,
                amt: 2290,
            },
            {
                name: 'Avril 13',
                ventes: 2780,
                pv: 3908,
                amt: 2000,
            },
            {
                name: 'Avril 16',
                ventes: 1890,
                pv: 4800,
                amt: 2181,
            },
            {
                name: 'Avril 19',
                ventes: 2390,
                pv: 3800,
                amt: 2500,
            },
            {
                name: 'Avril 22',
                ventes: 3490,
                pv: 4300,
                amt: 2100,
            },
        ],
        activeIndex: 0,
    };

    handleClick = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const { activeIndex, data } = this.state;
        const activeItem = data[activeIndex];

        return (
            <div style={{ width: '100%' }}>
                <ResponsiveContainer width="100%" height={150}>
                    <BarChart width={150} height={40} data={data}>
                        <Bar dataKey="ventes" onClick={this.handleClick}>
                            {data.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? '#82ca9d' : '#8884d8'} key={`cell-${index}`} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
                <p className="content">{`${activeItem.name} Ventes : ${activeItem.ventes}$ `}</p>
            </div>
        );
    }
}

import React, { useEffect, useState } from "react";
import { renderToast } from "../../../helpers/utils/Toast";
import api from "../../../api";
import Moment from "react-moment";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { IoWalletOutline } from "react-icons/io5";
import { BsCalendarDate } from "react-icons/bs";
import BlankImage from "../../../media/blank-image.svg";
import { HiOutlineMail } from "react-icons/hi";
import _ from "lodash";
import { FaStripe } from "react-icons/fa";
import {
  AiOutlineCheckCircle,
  AiOutlinePhone,
  AiOutlineUser,
} from "react-icons/ai";
import { Spinner } from "react-bootstrap";
function QuotationSummary() {
  const [quotation, setQuotation] = useState({});
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(true);
  function fetchQuotation() {
    api
      .get("api/customers/quotations/" + params.id)
      .then((response) => {
        setQuotation(response.data.data);
      })
      .catch((error) => {
        var message = error.response.data.message;
        renderToast(message, "error");
      });
  }

  function checkout() {
    setIsLoading(true);
    let origin_url = window.location.origin;

    let success_url = `${origin_url}/quotations/${params.id}?session_id={CHECKOUT_SESSION_ID}`;

    let cancel_url = `${origin_url}/quotations/${params.id}?session_id={CHECKOUT_SESSION_ID}`;

    api
      .post(`api/quotations/${params.id}/checkout`, {
        success_url: success_url,
        cancel_url: cancel_url,
      })
      .then((response) => {
        window.open(response.data.data.checkout_url, "_blank");
      })
      .catch((error) => {
        var message = error.response.data.message;
        renderToast(message, "error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function checkSession() {
    api
      .post(`api/quotations/${params.id}/check-session`, {
        sessionId: searchParams.get("session_id"),
      })
      .then((response) => {
        setIsPaid(true);
      })
      .catch((error) => {
        var message = error.response.data.message;
        renderToast(message, "error");
      })
      .finally(() => {
        setPaymentLoading(false);
      });
  }
  useEffect(() => {
    fetchQuotation();
  }, []);

  useEffect(() => {
    if (searchParams.get("session_id") !== null) {
      checkSession();
    } else {
      setTimeout(() => {
        setPaymentLoading(false);
      }, 1000);
    }
  }, []);

  return (
    <div className="container py-20">
      <div className="d-flex gap-8 w-100 h-100">
        <div className="card card-flush shadow-sm py-4 flex-row-fluid">
          <div className="card-header">
            <div className="card-title">
              <h3>Quotation Details</h3>
            </div>
          </div>
          <div className="card-body pt-0">
            {quotation ? (
              <>
                <div className="table-responsive">
                  <table className="table align-middle  mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-bold text-gray-600">
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex gap-2 align-items-center">
                            <IoWalletOutline size={24} />
                            Number
                          </div>
                        </td>
                        <td className="fw-bolder text-end">
                          {quotation.quotation_number}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex gap-2 align-items-center">
                            <BsCalendarDate size={24} />
                            Created at
                          </div>
                        </td>
                        <td className="fw-bolder text-end">
                          {quotation.created_at ? (
                            <Moment
                              format="DD/MM/YYYY H:mm"
                              date={quotation.created_at}
                            ></Moment>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="card-title mt-4">
                  <h3>Products</h3>
                </div>
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th className="min-w-175px"></th>
                        <th className="min-w-70px text-end">Qty</th>
                        <th className="min-w-100px text-end">Unit Price</th>
                        <th className="min-w-100px text-end">Total</th>
                      </tr>
                    </thead>

                    <tbody className="fw-bold text-gray-600">
                      {!_.isEmpty(quotation.products) &&
                        quotation.products.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <a className="symbol symbol-50px">
                                    <span
                                      className="symbol-label"
                                      style={{
                                        backgroundImage: `url(${
                                          item.product.default_image
                                            ? item.product.default_image
                                            : BlankImage
                                        })`,
                                      }}
                                    ></span>
                                  </a>
                                  <div className="ms-5">
                                    <a className="fw-bolder text-gray-600 text-hover-primary">
                                      {item.product.name}
                                    </a>
                                    <div className="fs-7 text-muted">
                                      Delivery Date: 20/02/2022
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">{item.number}</td>
                              <td className="text-end">${item.price}</td>
                              <td className="text-end">
                                ${item.price * item.number}
                              </td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colSpan="3" className="fs-3 text-dark text-end">
                          Grand Total
                        </td>
                        <td className="text-dark fs-3 fw-boldest text-end">
                          ${quotation?.total_price}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="overlay overlay-block card-rounded h-100">
                <div className="overlay-wrapper p-5">
                  <div className="table-responsive">
                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                      <tbody className="fw-bold text-gray-600">
                        <tr>
                          <td className="text-muted">
                            <div className="d-flex align-items-center">
                              <span className="svg-icon svg-icon-2 me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    opacity="0.3"
                                    d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z"
                                    fill="black"
                                  ></path>
                                </svg>
                              </span>
                              Number
                            </div>
                          </td>
                          <td className="fw-bolder text-end"></td>
                        </tr>
                        <tr>
                          <td className="text-muted">
                            <div className="d-flex align-items-center">
                              <span className="svg-icon svg-icon-2 me-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z"
                                    fill="black"
                                  ></path>
                                  <path
                                    d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z"
                                    fill="black"
                                  ></path>
                                </svg>
                              </span>
                              Created at
                            </div>
                          </td>
                          <td className="fw-bolder text-end"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="overlay-layer card-rounded bg-dark bg-opacity-5">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex h-100 flex-column justify-content-between gap-4">
          <div className="card card-flush h-100 shadow-sm py-4 flex-row-fluid">
            <div className="card-header">
              <div className="card-title">
                <h3>Customer Details</h3>
              </div>
            </div>
            <div className="card-body pt-0">
              {quotation.customer_data ? (
                <div className="table-responsive">
                  <table className="table align-middle mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-bold text-gray-600">
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex gap-2 align-items-center">
                            <AiOutlineUser size={20} />
                            Customer
                          </div>
                        </td>
                        <td className="fw-bolder ">
                          {quotation.customer_data.is_corporate == 1 ? (
                            <span>{quotation?.customer?.company_name}</span>
                          ) : (
                            <span>
                              {quotation?.customer?.firstname}{" "}
                              {quotation?.customer?.lastname}{" "}
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="text-muted">
                          <div className="d-flex gap-2 align-items-center">
                            <HiOutlineMail size={20} />
                            Email
                          </div>
                        </td>
                        <td className="fw-bolder ">
                          {quotation.customer_data.email}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-muted">
                          <div className="d-flex gap-2 align-items-center">
                            <AiOutlinePhone size={20} />
                            Phone
                          </div>
                        </td>
                        <td className="fw-bolder ">
                          {quotation.customer_data.mobile}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="overlay overlay-block card-rounded h-100">
                  <div className="overlay-wrapper p-5">
                    <div className="table-responsive">
                      <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                        <tbody className="fw-bold text-gray-600">
                          <tr>
                            <td className="text-muted">
                              <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                      fill="black"
                                    ></path>
                                  </svg>
                                </span>
                                Customer
                              </div>
                            </td>
                            <td className="fw-bolder text-end">
                              <div className="d-flex align-items-center justify-content-end">
                                <a
                                  href="/metronic8/demo18/../demo18/apps/ecommerce/customers/details.html"
                                  className="text-gray-600 text-hover-primary"
                                >
                                  {quotation?.customer?.company_name}
                                </a>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="text-muted">
                              <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                      fill="black"
                                    ></path>
                                  </svg>
                                </span>
                                Email
                              </div>
                            </td>
                            <td className="fw-bolder text-end">
                              <a
                                href="/metronic8/demo18/../demo18/apps/user-management/users/view.html"
                                className="text-gray-600 text-hover-primary"
                              ></a>
                            </td>
                          </tr>

                          <tr>
                            <td className="text-muted">
                              <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2 me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      opacity="0.3"
                                      d="M19 4H5V20H19V4Z"
                                      fill="black"
                                    ></path>
                                  </svg>
                                </span>
                                Phone
                              </div>
                            </td>
                            <td className="fw-bolder text-end"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="overlay-layer card-rounded bg-dark bg-opacity-5">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card card-flush h-100 shadow-sm py-4 flex-row-fluid">
            <div className="card-header" style={{ minHeight: "40px" }}>
              <div className="card-title">
                <h3>Address Details</h3>
              </div>
            </div>
            <div className="card-body px-10 mt-0">
              {quotation?.customer_data?.address ? (
                <span>
                  {quotation?.customer_data?.address.street},{" "}
                  {quotation?.customer_data?.address.city},{" "}
                  {quotation?.customer_data?.address.region},{" "}
                  {quotation?.customer_data?.address.zipcode}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          {paymentLoading ? (
            <div className="alert mb-0 alert-info d-flex justify-content-center align-items-center gap-2">
              <Spinner /> <span>Processing</span>
            </div>
          ) : (
            <>
              {!isPaid ? (
                <div className="d-flex flex-cloumn h-25 w-100 gap-4 justify-content-between">
                  <button
                    disabled={isLoading}
                    className="btn btn-success"
                    onClick={() => {
                      checkout();
                    }}
                  >
                    {isLoading ? (
                      <div className="d-flex align-items-center gap-2">
                        <Spinner /> <span>Processing</span>
                      </div>
                    ) : (
                      <>
                        Pay with <FaStripe size={32} />
                      </>
                    )}
                  </button>
                  <button className="btn btn-danger">
                    <span> Pay with </span>
                    <span className="fw-bolder">ClickToPay</span>
                  </button>
                </div>
              ) : isPaid ? (
                <div className="alert alert-green h-25 p-6 mb-0 d-flex justify-content-center align-items-center gap-2">
                  <AiOutlineCheckCircle size={28} />
                  <span>Payment Completed</span>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuotationSummary;

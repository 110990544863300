import React, { Component } from 'react';

class CustomSubMenu extends Component {

    render() {
        const icon = { nav: this.props.icon_nav };
        return (
            <span onClick={() => this.props.customNavLink(this.props.link)} className="menu-item menu-accordion">
                <div className="menu-link">
                    <span className="menu-icon"> <icon.nav className="fs-2" /> </span>
                    <span className="menu-text custom-fs-6">{this.props.nav_name}</span>
                </div>
            </span >
        );
    }
}

export default CustomSubMenu;
import React from "react";
import Empty from "../../../helpers/utils/Empty";
import _ from "lodash";

const Installments = ({ installments_list }) => {
  var number_days = 0;
  var due_amount = 0;
  return (
    <div className="tab-pane fade show active " role="tab-panel">
      <div className="d-flex flex-column gap-7 gap-lg-10">
        <div className="card card-flush py-4 flex-row-fluid">
          <div className="card-header">
            <div className="card-title">
              <h2>Installments</h2>
            </div>
          </div>
          <div className="card-body pt-0">
            {!_.isEmpty(installments_list) ? (
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                  <thead>
                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                      <th className="min-w-100px">Id</th>
                      <th className="min-w-175px">Code</th>
                      <th className="min-w-100px">Number Days</th>
                      <th className="min-w-70px">Date start</th>
                      <th className="min-w-70px">Date end</th>
                      <th className="min-w-100px">Amount due</th>
                      <th className="min-w-100px">Date due</th>
                    </tr>
                  </thead>
                  <tbody className="fw-bold text-gray-600">
                    {installments_list.map((item) => {
                      number_days += item.number_days;
                      due_amount += item.due_amount;

                      return (
                        <tr key={item.code}>
                          <td>{item.id}</td>
                          <td>{item.code}</td>
                          <td>{item.number_days}</td>
                          <td>{item.date_begin}</td>
                          <td>{item.date_end}</td>
                          <td>{item.due_amount}</td>
                          <td>{item.date_due}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="2" className="fs-3 text-dark text-end">
                        Total
                      </td>
                      <td className="text-dark fs-3 fw-boldest ">
                        {number_days}
                      </td>
                      <td colSpan="2" className="fs-3 text-dark text-end"></td>
                      <td className="text-dark fs-3 fw-boldest">
                        {due_amount.toFixed(2)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <Empty
                  description={"No items."}
                  img_heigth="70px"
                  img_width="90px"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Installments;

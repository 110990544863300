import React from "react";
import { useNavigate } from "react-router-dom";
import Charts from "../../../components/Charts/Chart";
import BarChartWithCustomizedEvent from "../../../components/Charts/BarChartWithCustomizedEvent";
import { IoIosList } from "react-icons/io";

const Index = () => {
  const navigate = useNavigate();
  return (
    <div className="app-page flex-column flex-column-fluid zindex-2 bg-white">
      <div
        className="app-wrapper flex-column flex-row-fluid"
        id="kt_app_wrapper"
      >
        <div className="app-container container-fluid d-flex flex-row flex-column-fluid">
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
              <div
                className="app-content flex-column-fluid"
                id="kt_app_content"
              >
                <div className="row g-5 g-xl-10 mb-xl-10">
                  <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                    <div className="card card-flush shadow-sm h-md-50 mb-5 mb-xl-10">
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">
                              $
                            </span>
                            <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">
                              69,700
                            </span>
                            <span className="badge badge-primary fs-base">
                              <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.5"
                                    x={13}
                                    y={6}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(90 13 6)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              2.2%
                            </span>
                          </div>
                          <span className="text-gray-400 pt-1 fw-bold fs-6">
                            Bénéfices attendus
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-2 pb-4 d-flex align-items-center">
                        <div className="d-flex flex-center me-5 pt-2">
                          <div style={{ minWidth: 70, minHeight: 70 }}>
                            <span />
                            <canvas height={70} width={70} />
                          </div>
                        </div>
                        <div className="d-flex flex-column content-justify-center w-100">
                          <div className="d-flex fs-6 fw-bold align-items-center">
                            <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />
                            <div className="text-gray-500 flex-grow-1 me-4">
                              Assurance des personnes
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end">
                              $7,660
                            </div>
                          </div>
                          <div className="d-flex fs-6 fw-bold align-items-center my-3">
                            <div className="bullet w-8px h-6px rounded-2 bg-primary me-3" />
                            <div className="text-gray-500 flex-grow-1 me-4">
                              Maritime et transport
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end">
                              $2,820
                            </div>
                          </div>
                          <div className="d-flex fs-6 fw-bold align-items-center">
                            <div
                              className="bullet w-8px h-6px rounded-2 me-3"
                              style={{ backgroundColor: "#E4E6EF" }}
                            />
                            <div className="text-gray-500 flex-grow-1 me-4">
                              Autres
                            </div>
                            <div className="fw-boldest text-gray-700 text-xxl-end">
                              $45,257
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card card-flush shadow-sm h-md-50 mb-xl-10">
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">
                              1,836
                            </span>
                            <span className="badge badge-danger fs-base">
                              <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.5"
                                    x={11}
                                    y={18}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-90 11 18)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              2.2%
                            </span>
                          </div>
                          <span className="text-gray-400 pt-1 fw-bold fs-6">
                            Souscriptions ce mois-ci
                          </span>
                        </div>
                      </div>
                      <div className="card-body d-flex align-items-end pt-0">
                        <div className="d-flex align-items-center flex-column mt-3 w-100">
                          <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                            <span className="fw-boldest fs-6 text-dark">
                              1 048 à l’objectif
                            </span>
                            <span className="fw-bolder fs-6 text-gray-400">
                              62%
                            </span>
                          </div>
                          <div className="h-8px mx-3 w-100 bg-light-success rounded">
                            <div
                              className="bg-success rounded h-8px"
                              role="progressbar"
                              style={{ width: "62%" }}
                              aria-valuenow={50}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                    <div className="card card-flush shadow-sm h-md-50 mb-5 mb-xl-10">
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">
                              $
                            </span>
                            <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">
                              2,420
                            </span>
                            <span className="badge badge-primary fs-base">
                              <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.5"
                                    x={13}
                                    y={6}
                                    width={13}
                                    height={2}
                                    rx={1}
                                    transform="rotate(90 13 6)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              2.6%
                            </span>
                          </div>
                          <span className="text-gray-400 pt-1 fw-bold fs-6">
                            Ventes quotidiennes moyennes
                          </span>
                        </div>
                      </div>
                      <BarChartWithCustomizedEvent />
                    </div>
                    <div className="card card-flush shadow-sm h-md-50 mb-xl-10">
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">
                            6.3k
                          </span>
                          <span className="text-gray-400 pt-1 fw-bold fs-6">
                            Nouveaux clients ce mois-ci
                          </span>
                        </div>
                      </div>
                      <div className="card-body d-flex flex-column justify-content-end pe-0">
                        <span className="fs-6 fw-boldest text-gray-800 d-block mb-2">
                          Les héros d’aujourd’hui
                        </span>
                        <div className="symbol-group symbol-hover flex-nowrap">
                          <div
                            className="symbol symbol-35px symbol-circle"
                            data-bs-toggle="tooltip"
                            title=""
                          >
                            <span className="symbol-label bg-warning text-inverse-warning fw-bolder">
                              A
                            </span>
                          </div>
                          <div
                            className="symbol symbol-35px symbol-circle"
                            title=""
                          >
                            <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                              S
                            </span>
                          </div>
                          <div
                            className="symbol symbol-35px symbol-circle"
                            title=""
                          >
                            <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                              S
                            </span>
                          </div>
                          <div
                            className="symbol symbol-35px symbol-circle"
                            title=""
                          >
                            <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                              B
                            </span>
                          </div>
                          <div
                            className="symbol symbol-35px symbol-circle"
                            title=""
                          >
                            <span className="symbol-label bg-danger text-inverse-danger fw-bolder">
                              P
                            </span>
                          </div>
                          <div
                            className="symbol symbol-35px symbol-circle"
                            title=""
                          >
                            <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                              A
                            </span>
                          </div>
                          <a
                            href="#"
                            className="symbol symbol-35px symbol-circle"
                          >
                            <span className="symbol-label bg-light text-gray-400 fs-8 fw-bolder">
                              +42
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                    <div className="card card-flush shadow-sm overflow-hidden h-md-100">
                      <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-dark">
                            Ventes ce mois-ci{" "}
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            Utilisateurs de tous les canaux
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end">
                            <span className="svg-icon svg-icon-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.3"
                                  x={2}
                                  y={2}
                                  width={20}
                                  height={20}
                                  rx={4}
                                  fill="currentColor"
                                />
                                <rect
                                  x={11}
                                  y={11}
                                  width="2.6"
                                  height="2.6"
                                  rx="1.3"
                                  fill="currentColor"
                                />
                                <rect
                                  x={15}
                                  y={11}
                                  width="2.6"
                                  height="2.6"
                                  rx="1.3"
                                  fill="currentColor"
                                />
                                <rect
                                  x={7}
                                  y={11}
                                  width="2.6"
                                  height="2.6"
                                  rx="1.3"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                          <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px">
                            <div className="separator mb-3 opacity-75" />
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                New Ticket
                              </a>
                            </div>
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                New Customer
                              </a>
                            </div>
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                <span className="menu-title">New Group</span>
                                <span className="menu-arrow" />
                              </a>
                              <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    Admin Group
                                  </a>
                                </div>
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    Staff Group
                                  </a>
                                </div>
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    Member Group
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                New Contact
                              </a>
                            </div>

                            <div className="separator mt-3 opacity-75" />
                            <div className="menu-item px-3">
                              <div className="menu-content px-3 py-3">
                                <a
                                  className="btn btn-primary btn-sm px-4"
                                  href="#"
                                >
                                  Generate Reports
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body d-flex justify-content-around flex-column pb-1 px-0">
                        <div className="px-9 mb-5">
                          <div className="d-flex mb-2">
                            <span className="fs-4 fw-bold text-gray-400 me-1">
                              $
                            </span>
                            <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1 ls-n2">
                              14,094
                            </span>
                          </div>
                          <span className="fs-6 fw-bold text-gray-400">
                            Un autre 48 346 $ à l’objectif
                          </span>
                        </div>
                        <Charts />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-6 mb-5 mb-xl-10">
                    <div className="card card-flush shadow-sm h-xl-100">
                      <div className="card-header pt-7">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Souscriptions
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            57 Souscriptions en moyenne par jour
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <div className="d-flex flex-stack flex-wrap gap-4">
                            <div className="position-relative my-1">
                              <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control w-150px fs-7 ps-12"
                                placeholder="Rechercher"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pt-2">
                        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div className="table-responsive">
                            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                              <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Customer: activate to sort column ascending"
                                    style={{ width: "187.234px" }}
                                  >
                                    Client
                                  </th>
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Status: activate to sort column ascending"
                                    style={{ width: "184.826px" }}
                                  >
                                    Status
                                  </th>
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Billing: activate to sort column ascending"
                                    style={{ width: "209.63px" }}
                                  >
                                    Facturation
                                  </th>
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Product: activate to sort column ascending"
                                    style={{ width: "195.347px" }}
                                  >
                                    Produit
                                  </th>
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Created Date: activate to sort column ascending"
                                    style={{ width: "184.826px" }}
                                  >
                                    Date de création
                                  </th>
                                  <th
                                    className="text-end min-w-70px sorting_disabled"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions"
                                    style={{ width: "145.451px" }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-gray-600 fw-bold">
                                <tr className="odd">
                                  <td>
                                    <a
                                      className="text-gray-800 text-hover-primary mb-1 cursor-pointer"
                                      onClick={() => navigate("/client-view")}
                                    >
                                      Emma Smith
                                    </a>
                                  </td>
                                  <td>
                                    <div className="badge badge-light-success">
                                      Actif
                                    </div>
                                  </td>
                                  <td>
                                    <div className="badge badge-light">
                                      Auto-débit
                                    </div>
                                  </td>
                                  <td>Basique</td>
                                  <td data-order="2022-01-20T00:00:00+01:00">
                                    Jun 20, 2022
                                  </td>
                                  <td className="text-end cursor-pointer">
                                    <IoIosList
                                      size={25}
                                      onClick={() => navigate("/subscriptions")}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                              <div className="dataTables_paginate paging_simple_numbers">
                                <ul className="pagination">
                                  <li className="paginate_button page-item previous disabled">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      <i className="previous" />
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item ">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item next">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      <i className="next" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 mb-5 mb-xl-10">
                    <div className="card card-flush shadow-sm h-xl-100">
                      <div className="card-header pt-7">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bolder text-gray-800">
                            Devis
                          </span>
                          <span className="text-gray-400 mt-1 fw-bold fs-6">
                            57 devis en moyenne par jour
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <div className="d-flex flex-stack flex-wrap gap-4">
                            <div className="position-relative my-1">
                              <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height={2}
                                    rx={1}
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control w-150px fs-7 ps-12"
                                placeholder="Rechercher"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pt-2">
                        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div className="table-responsive">
                            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                              <thead>
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Customer: activate to sort column ascending"
                                    style={{ width: "187.234px" }}
                                  >
                                    Client
                                  </th>
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Status: activate to sort column ascending"
                                    style={{ width: "184.826px" }}
                                  >
                                    Status
                                  </th>
                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Billing: activate to sort column ascending"
                                    style={{ width: "209.63px" }}
                                  >
                                    Numéro
                                  </th>

                                  <th
                                    className="min-w-125px sorting"
                                    tabIndex={0}
                                    aria-controls="kt_subscriptions_table"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Created Date: activate to sort column ascending"
                                    style={{ width: "184.826px" }}
                                  >
                                    Date de création
                                  </th>
                                  <th
                                    className="text-end min-w-70px sorting_disabled"
                                    rowSpan={1}
                                    colSpan={1}
                                    aria-label="Actions"
                                    style={{ width: "145.451px" }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-gray-600 fw-bold">
                                <tr className="odd">
                                  <td>
                                    <a
                                      className="text-gray-800 text-hover-primary mb-1 cursor-pointer"
                                      onClick={() => navigate("/client-view")}
                                    >
                                      Emma Smith
                                    </a>
                                  </td>
                                  <td>
                                    <div className="badge badge-light-success">
                                      Active
                                    </div>
                                  </td>
                                  <td>
                                    <div className="badge badge-light">
                                      23457
                                    </div>
                                  </td>
                                  <td>Jun 20, 2022</td>
                                  <td className="text-end cursor-pointer">
                                    <IoIosList
                                      size={25}
                                      onClick={() => navigate("/quotations")}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                              <div className="dataTables_paginate paging_simple_numbers">
                                <ul className="pagination">
                                  <li className="paginate_button page-item previous disabled">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      <i className="previous" />
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item active">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      1
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item ">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      2
                                    </a>
                                  </li>
                                  <li className="paginate_button page-item next">
                                    <a
                                      href="#"
                                      aria-controls="kt_subscriptions_table"
                                      tabIndex={0}
                                      className="page-link"
                                    >
                                      <i className="next" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

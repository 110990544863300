import { renderToast } from "../../helpers/utils/Toast"
import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_QTE ,REMOVE_CART} from "../constants/cart.constant"


export const addToCart = (payload) => async (dispatch) => {
    try {
        dispatch({ type: ADD_TO_CART, payload })
        renderToast("Produit ajouté","success")
    } catch (error) {
        console.log(error)
    }
}

export const removeFromCart = (payload) => async (dispatch) => {
    try {
        dispatch({ type: REMOVE_FROM_CART, payload })
    } catch (error) {
        console.log(error)
    }
}

export const updateQte = (payload) => async (dispatch) => {

    try {
        dispatch({ type: UPDATE_QTE, payload })
    } catch (error) {
        console.log(error)

    }

}
export const removeCart = () => async (dispatch) => {

    try {
        dispatch({ type: REMOVE_CART })
        
    } catch (error) {
        console.log(error)

    }

}
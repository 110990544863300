import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import NavBar from "../Layout/NavBar/NavBar";

const RestPassword = () => {
  const navigate = useNavigate();
  const loading = false;
  return (
    <div className="d-flex flex-column flex-root">
      <NavBar />
      <div className=" d-flex flex-row-fluid position-relative p-7 mt-20">
        <div className="d-flex flex-center flex-column flex-column-fluid pt-20">
          <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <Formik
              initialValues={{
                password: "",
                passwordConfirmation: "",
              }}
              validationSchema={Yup.object({
                password: Yup.string().required("Password is required"),
                passwordConfirmation: Yup.string().oneOf(
                  [Yup.ref("password"), null],
                  "Passwords must match"
                ),
              })}
              onSubmit={(fields) => {}}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="text-center mb-10 ">
                    <h1 className="text-dark mb-3">
                      Réinitialiser le mot de passe
                    </h1>
                  </div>
                  <div className="form-group mb-5">
                    <label htmlFor="password">Mot de passe</label>
                    <Field
                      name="password"
                      type="text"
                      className={
                        "form-control form-control-lg form-control-solid" +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "") +
                        (!errors.password && touched.password
                          ? " is-valid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group mb-5">
                    <label htmlFor="passwordConfirmation">
                      {" "}
                      Confirmation du mot de passe
                    </label>

                    <Field
                      name="passwordConfirmation"
                      type="email"
                      className={
                        "form-control form-control-lg form-control-solid" +
                        (errors.passwordConfirmation &&
                        touched.passwordConfirmation
                          ? " is-invalid"
                          : "") +
                        (!errors.passwordConfirmation &&
                        touched.passwordConfirmation
                          ? " is-valid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="passwordConfirmation"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="text-center mt-10">
                    <Button
                      type="submit"
                      className="btn rounded-4 btn-warning "
                      style={{ width: "15 rem" }}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            className="mr-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Envoi en cours ...</span>
                        </>
                      ) : (
                        <span>Valider</span>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestPassword;

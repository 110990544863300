import React from 'react'
import { getStoreData } from '../../helpers/cookie'

const Steps = () => {
  const store= getStoreData().organization
  return (
    <section style={{ backgroundColor: "#fff7ef", padding: "100px 100px 150px 100px", height: "100%" }}>
      <div className='text-center mb-15'>
        <h4 style={{
          marginBottom: "20px",
          fontSize: "20px"
        }} > Que faisons-nous ? </h4>
        <h6 className="text-muted" style={{}}> Commencez à travailler avec  <span className='text-warning font-weight-bold'> {store?.company_name} </span> </h6>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-3">
          <div className="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div className="icons text-center mx-auto text-text-white">
              <span
                className="d-block rounded h3 mb-0"
                style={{
                  height: 65,
                  width: 65,
                  lineHeight: 4,
                  backgroundColor: "#ff5e10"
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                    fill="currentColor"
                  />
                  <rect
                    opacity="0.3"
                    x={14}
                    y={4}
                    width={4}
                    height={4}
                    rx={2}
                    fill="currentColor"
                  />
                  <path
                    d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                    fill="currentColor"
                  />
                  <rect
                    opacity="0.3"
                    x={6}
                    y={5}
                    width={6}
                    height={6}
                    rx={3}
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-2">
              <h5 className="text-dark">Données client</h5>
              <p className="text-muted mb-0">
                Rechercher par siren ou Raison sociale
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ transform: " translateY(40%)" }}>
          <div className="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div className="icons text-center mx-auto">
              <span
                className="d-block rounded h3 mb-0"
                style={{
                  height: 65,
                  width: 65,
                  lineHeight: 4,
                  backgroundColor: "#ff5e10"
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x={2}
                    y={2}
                    width={9}
                    height={9}
                    rx={2}
                    fill="currentColor"
                  />
                  <rect
                    opacity="0.3"
                    x={13}
                    y={2}
                    width={9}
                    height={9}
                    rx={2}
                    fill="currentColor"
                  />
                  <rect
                    opacity="0.3"
                    x={13}
                    y={13}
                    width={9}
                    height={9}
                    rx={2}
                    fill="currentColor"
                  />
                  <rect
                    opacity="0.3"
                    x={2}
                    y={13}
                    width={9}
                    height={9}
                    rx={2}
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-2">
              <h5 className="text-dark">Choisir un produit</h5>
              <p className="text-muted mb-0">Choisir un produit</p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ transform: " translateY(80%)" }}>
          <div className="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div className="icons text-center mx-auto">
              <span
                className="d-block rounded h3 mb-0"
                style={{
                  height: 65,
                  width: 65,
                  lineHeight: 4,
                  backgroundColor: "#ff5e10"
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    opacity="0.3"
                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
            <div className="mt-2">
              <h5 className="text-dark">Souscrire</h5>
              <p className="text-muted mb-0">souscrire</p>
            </div>
          </div>
        </div>
      </div>
    </section>)
}

export default Steps
import React, { Component } from "react";
import { Route, Routes, Switch } from "react-router-dom";
import _ from "lodash";

import Products from "./components/Products";
import Ventilations from "./components/Ventilations";
import Details from "./components/Details";
import Installments from "./components/Installments";
import api from "../../api";
import { renderToast } from "../../helpers/utils/Toast";

import CustomerInfo from "./CustomerInfo";
import QuotationInfo from "./QuotationInfo";
import { withRouter } from "../../routes/withRouter";
import Payments from "./components/Payments";

class index extends Component {
  location_pathname = this.props.router.location.pathname;
  quotation_id = this.props.router.params.id;

  state = {
    visibleTab: 1,
    quotation_data: {},
    installments_list: [],
    AlertShow: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchQuotation();
  }
  fetchQuotation() {
    api
      .get("api/customers/quotations/" + this.quotation_id)
      .then((response) => {
        this.setState({ quotation_data: response.data.data });
      })
      .catch((error) => {
        var message = error.response.data.message;
        renderToast(message, "error");
      });
  }
  checkAlert = (item) => {
    this.setState({
      AlertShow: item,
    });
  };

  tabs_data = [
    {
      id: 1,
      tabTitle: "Products",
    },
    {
      id: 2,
      tabTitle: "Installments",
    },
    {
      id: 3,
      tabTitle: "Managerial Accounting",
    },
    {
      id: 4,
      tabTitle: "Ventilations",
    },
    {
      id: 5,
      tabTitle: "Payments",
    },
  ];

  listTabs() {
    return this.tabs_data.map((item,i) => (
      <li className="nav-item mt-2" key={i}>
        <a
          className={
            this.state.visibleTab == item.id
              ? "nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer active"
              : "nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer"
          }
          onClick={() => {
            this.setState({ visibleTab: item.id });
          }}
        >
          {item.tabTitle}
        </a>
      </li>
    ));
  }

  render() {
    var quotation_data = this.state.quotation_data;
    return (
      <div className=" flex-column flex-row-fluid mx-5 ">
        <div className=" d-flex flex-row flex-column-fluid">
          <div className="app-main flex-column flex-row-fluid">
            <div className="d-flex flex-column-fluid align-items-start container-xxl mt-20">
              <div className="content flex-row-fluid" id="kt_content">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                  <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                    <QuotationInfo quotation={quotation_data} />
                    <CustomerInfo customer={quotation_data.customer} />
                  </div>
                  <div className="card card-flush  shadow-sm py-4 flex-row-fluid mb-10">
                    <div className="card-body pt-0">
                      <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-lg-n2 me-auto">
                          {this.listTabs()}
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div
                          className={
                            this.state.visibleTab == `1`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <Products quotation_data={quotation_data} />
                        </div>
                        <div
                          className={
                            this.state.visibleTab == `2`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <Installments
                            installments_list={quotation_data.installments}
                          />
                        </div>
                        <div
                          className={
                            this.state.visibleTab == `3`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <Details quotation_data={quotation_data} />
                        </div>
                        <div
                          className={
                            this.state.visibleTab == `5`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <Payments quotation_data={quotation_data} fetchQuotation={this.fetchQuotation.bind(this)} />
                        </div>
                        <div
                          className={
                            this.state.visibleTab == `4`
                              ? "tab-pane fade active show"
                              : "tab-pane fade "
                          }
                        >
                          <Ventilations quotation_data={quotation_data} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(index);

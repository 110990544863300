import { FETCH_CUSTOMER, FETCH_CUSTOMER_FAILED, FETCH_CUSTOMER_SUCCESS } from "../constants/customer.constant.js"


const initialState = {
    isLoading: false,
    customer_data: {},
    contacts: [],
    error: {},
    addresses: []
}

const customerReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case FETCH_CUSTOMER:
            return {
                ...state,
                isLoading: true
            }

        case FETCH_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customer_data: payload.customer,
                contacts: payload.customer.CustomerContacts,
                addresses: payload.customer.CustomerAddresses
            }

        case FETCH_CUSTOMER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            }

        default: return state
    }
}

export default customerReducer
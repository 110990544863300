import React, { Component } from "react";
import Moment from "react-moment";

import Empty from "../../../helpers/utils/Empty";
import { withRouter } from "../../../routes/withRouter";

class Details extends Component {
  quotation_id = this.props.router.params.id;

  state = {
    details_list: this.props.quotation_data.details,
  };

  render() {
    const details_list = this.state.details_list ? this.state.details_list : [];
    var amount = 0;
    return (
      <div className="tab-pane fade show active " role="tab-panel">
        <div className="d-flex flex-column gap-7 gap-lg-10">
          <div className="card card-flush py-4 flex-row-fluid">
            <div className="card-header">
              <div className="card-title">
                <h2>Details</h2>
              </div>
            </div>
            <div className="card-body pt-0">
              {details_list.length > 0 ? (
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th>Id</th>
                        <th>Installment</th>
                        <th>Product</th>
                        <th>Beneficiary</th>
                        <th>Amount</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody className="fw-bold text-gray-600">
                      {details_list.map((item) => {
                        amount += item.amount;

                        return (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.installment.code}</td>
                            <td>{item.product.name}</td>
                            <td>{item.beneficiary_name}</td>
                            <td>{item.amount}</td>
                            <td>
                              {item.created_at ? (
                                <Moment
                                  format="DD/MM/YYYY H:mm"
                                  date={item.created_at}
                                ></Moment>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan="4" className="fs-3 text-dark text-end">
                          Total
                        </td>
                        <td className="text-dark fs-3 fw-boldest">
                          {amount.toFixed(2)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <>
                  <div className="mt-5 text-center">
                    <Empty
                      description={"No items."}
                      img_heigth="70px"
                      img_width="90px"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Details);
